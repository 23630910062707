import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import COL from "../constants/Colors";
import Logo from "../layout/Logo";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { theme } from "../constants/Config";
import BestelEenvoudig from "../layout/BestelEenvoudig";
import Reviews from "../layout/Reviews";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core/styles";
import SiteMap from "../layout/SiteMap";
import SideBar from "../layout/SideBar";
import { SERVER } from "../constants/Config";
import axios from "axios";
import i18n from "../../i18n";
import CircularProgress from "@material-ui/core/CircularProgress";

function Terms({ t }) {
  const [loading, setLoading] = React.useState(false);
  const [terms, setTerms] = React.useState("");

  useEffect(() => {
    setLoading(true);
    const _loadText = async () => {
      let lng = i18n.language;
      if (lng === "") lng = "nl";
      axios
        .get(SERVER + `terms/${lng}/terms.txt`)
        .then((res) => {
          if (res.data) {
            setTerms(res.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
    _loadText();
  }, [t]);

  const url = SERVER + `terms/privacy.pdf`;

  return (
    <Container maxWidth="md">
      <Card
        id="mobileView"
        style={{
          boxShadow: "0 18px 60px 0 rgba(15, 33, 37, 0.2)",
          padding: 50,
          borderRadius: 0,
        }}
      >
        <CardContent>
          {loading ? (
            <CircularProgress disableShrink />
          ) : (
            <ThemeProvider theme={theme}>
              <a href={url}>({t("pdfversion")})</a>
              <p
                style={{
                  fontFamily: "Poppins, sans-serif",
                  whiteSpace: "pre-line",
                  textAlign: "left",
                }}
              >
                {terms}
              </p>
            </ThemeProvider>
          )}
        </CardContent>
      </Card>
    </Container>
  );
}

export default function TermsAndConditions(props) {
  const [t, i18n] = useTranslation();
  const [drawer, setDrawer] = useState(false);
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = t("aboutusmetatile");
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"prices"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        {/* Sticky NavBat */}
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"prices"} openDrawer={setDrawer} />
        </Grid>

        {/* AboutUS */}
        <Grid
          item
          xs={12}
          style={{ marginTop: 15, marginBottom: 15, zIndex: 2 }}
        >
          <Terms t={t} />
        </Grid>
        {/* Banner */}
        <Grid item xs={12} style={{ backgroundColor: COL.orange }}>
          <BestelEenvoudig />
        </Grid>
        {/* Reviews */}
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", paddingBottom: "60px" }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12} className="gradientBG">
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
