import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    itemAdded(state, action) {
      let item = state[action.payload.id];
      if (item) {
        item.amount += 1;
        item.price = action.payload.price;
      } else {
        state[action.payload.id] = {
          amount: 1,
          price: action.payload.price,
        };
      }
    },
    itemDecremented(state, action) {
      let item = state[action.payload.id];
      if (item && item.amount > 1) item.amount -= 1;
      else delete state[action.payload.id];
    },
    itemRemoved(state, action) {
      delete state[action.payload.id];
    },
    clearCart(state, action) {
      return (state = {});
    },
  },
});

export const {
  itemAdded,
  itemRemoved,
  itemDecremented,
  clearCart,
} = shoppingCartSlice.actions;

export const selectAllInCart = (state) => {
  return state.shoppingCart;
};

export const selectTotal = (state) => {
  let totalAmount = 0;
  let totalPrice = 0;
  for (const [id, item] of Object.entries(state.shoppingCart)) {
    totalAmount += item.amount;
    totalPrice += item.amount * item.price;
  }
  return { totalAmount, totalPrice };
};

export const selectCartItem = (state, id) => {
  return state.shoppingCart[id];
};

export default shoppingCartSlice.reducer;
