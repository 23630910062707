import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import COL from "../constants/Colors";
import Logo from "../layout/Logo";
import { useTranslation } from "react-i18next";
import SideBar from "../layout/SideBar";
import BestelEenvoudig from "../layout/BestelEenvoudig";
import Reviews from "../layout/Reviews";
import SiteMap from "../layout/SiteMap";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Cart from "../layout/Cart";

export default function ShoppingCart(props) {
  const [drawer, setDrawer] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = t("cartmetatile");
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"prices"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"prices"} openDrawer={setDrawer} />
        </Grid>
        {/* Cart Items */}
        <Grid item xs={12} style={{ padding: "20px 0 20px 0" }}>
          <Cart isDesktop={isDesktop} />
        </Grid>
        {/* Banner */}
        <Grid item xs={12} style={{ backgroundColor: COL.orange }}>
          <BestelEenvoudig />
        </Grid>
        {/* Reviews */}
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", paddingBottom: "60px" }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12} className="gradientBG">
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
