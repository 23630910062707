import React from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CartEdit from "../layout/CartEdit";
import useProducts from "../../features/useProducts";
import LoadingIndicator from "../layout/LoadingIndicator";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  selectAllInCart,
  selectTotal,
} from "../../features/reducers/shoppingCartSlice";
import { useSelector } from "react-redux";
import EmptyShoppingcart from "../pages/EmptyShoppingcart";
import ButtonBase from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(() => ({
  img: {
    width: "auto",
    height: "auto",
    maxHeight: 90,
  },
  image: {
    width: 128,
    height: 128,
  },
  paper: {
    padding: 10,
    borderRadius: 0,
    border: "solid 2px #f7f6fb",
    boxShadow: "none",
  },
}));

function CartItem(props) {
  const t = props.t;

  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      style={{ padding: "10px" }}
    >
      <Paper className={classes.paper}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
          className={classes.gridSection}
        >
          <Grid item xs={12} md={3} id="mobileProImg">
            <ButtonBase className={classes.image} disabled>
              <img
                className={classes.img}
                src={props.data.icon}
                alt={t(props.data.name)}
              />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} md={9} container>
            <Grid
              item
              xs={12}
              md={8}
              align="left"
              id="mobileafrekeknenviewtitle"
            >
              <Typography
                style={{
                  paddingTop: "12px",
                  fontFamily: "Poppins, sans-serif",
                }}
                color="textPrimary"
              >
                {t(props.data.name)}
              </Typography>
              {props.isDesktop ? (
                <Typography
                  style={{ fontFamily: "Poppins, sans-serif" }}
                  color="textSecondary"
                >
                  {t(props.data.description)}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={4} align="right">
              <CartEdit {...props} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

function CheckoutDetails(props) {
  const t = props.t;
  const history = useHistory();
  let champ = "";
  let verzendcost = 25;
  let postPirce = 25;
  let price = props.total.totalPrice.toFixed(2);
  let total = 0;

  if (price < 25) {
    postPirce = postPirce - price;
    champ = "€" + postPirce.toFixed(2);
    total = verzendcost.toFixed(2);
  } else {
    total = props.total.totalPrice.toFixed(2);
    champ = t("free");
  }

  return (
    <Grid container align="left" direction="column">
      <Grid item xs>
        <Grid container>
          <Grid item xs align="left">
            <Typography
              style={{ fontFamily: "Poppins, sans-serif" }}
              color="textSecondary"
            >
              {t("subtotal")} ({props.total.totalAmount})
            </Typography>
          </Grid>
          <Grid item xs align="right">
            <Typography color="textPrimary">
              {
                <b style={{ fontSize: "1.3rem" }}>
                  €{props.total.totalPrice.toFixed(2)}
                </b>
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid container>
          <Grid item xs align="left">
            <Typography
              style={{ fontFamily: "Poppins, sans-serif" }}
              color="textSecondary"
            >
              {t("shipping")}
            </Typography>
          </Grid>
          <Grid item xs align="right">
            <Typography
              style={{
                fontFamily: "Poppins, sans-serif",
                color: "green",
                fontSize: "1.3rem",
              }}
            >
              {champ}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs align="center" style={{ margin: "10px 0 0 0" }}>
        <Typography
          style={{ fontFamily: "Poppins, sans-serif", color: "green" }}
        >
          {t("freeFrom")}
        </Typography>
      </Grid>
      <Grid item xs style={{ margin: "10px 0 10px 0" }}>
        <Divider />
      </Grid>
      <Grid item xs>
        <Grid container>
          <Grid item xs align="left">
            <Typography
              style={{ fontFamily: "Poppins, sans-serif" }}
              color="textSecondary"
            >
              {t("total")}
            </Typography>
          </Grid>
          <Grid item xs align="right">
            <Typography color="textPrimary">
              {<b style={{ fontSize: "1.3rem" }}>€{total}</b>}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs align="center" style={{ marginTop: "15px" }}>
        <Typography
          style={{ textAlign: "left", marginTop: "15px", fontFamily: "Poppins, sans-serif", color: "red" }}
        >
						LET OP: Er geldt tijdelijke ophaal- en bezorgmomenten: 
						<ul>
							<li>Maandag t/m Vrijdag: 10:00 tot 18:00 </li>
							<li>Zaterdag: 12:00 tot 18:00 </li>
							<li> Zondag: Geen ophalen en bezorgen</li>
						</ul>
        </Typography>
        <Button
          variant="contained"
          style={{
            backgroundColor: "rgb(52, 94, 228)",
            color: "white",
            borderRadius: 25,
            fontFamily: "Poppins, sans-serif",
          }}
          onClick={() => history.push("/wasmand/afrekenen/")}
        >
          {t("checkout")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default function Cart(props) {
  const cart = useSelector(selectAllInCart);
  const total = useSelector(selectTotal);
  const data = useProducts();
  const [t] = useTranslation();

  if (data.status !== "succeeded") return <LoadingIndicator />;

  //TODO: design me
  if (Object.entries(cart).length === 0) {
    return <EmptyShoppingcart t={t} />;
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={2}
        direction="column"
        align="center"
        wrap="nowrap"
      >
        <Grid item xs>
          <Card style={{ boxShadow: "0 18px 60px 0 rgba(15, 33, 37, 0.2)" }}>
            <CardContent>
              <Grid container direction="column" align="center" wrap="nowrap">
                {Object.entries(cart).map((arr, key) => {
                  return (
                    <Grid item xs key={key}>
                      <CartItem
                        {...props}
                        id={arr[0]}
                        amount={arr[1].amount}
                        totalPrice={(arr[1].price * arr[1].amount).toFixed(2)}
                        data={data.products[arr[0]]}
                        cart={cart}
                        t={t}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                container
                // id="mobileView"
                style={{ padding: 20 }}
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                  align="right"
                  style={{
                    marginTop: "20px",
                    padding: "20px",
                    backgroundColor: "#fffae5",
                  }}
                >
                  <CheckoutDetails t={t} total={total} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
