import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import useProducts from "../../features/useProducts";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

export default function TopDwonBar(props) {
  const [t] = useTranslation();

  const data = useProducts();
  let content;
  content = [];
  for (const cat of data.categories) {
    let url = "#" + cat.name;
    content.push(
      <a href={url} key={t(cat.name)}>
        <MenuItem
          style={{ fontFamily: "Poppins, sans-serif" }}
          id={cat.name}
          key={t(cat.name)}
          onClick={props.onClose}
        >
          {t(cat.name)}
        </MenuItem>
      </a>
    );
  }

  return (
    <Drawer anchor="bottom" open={props.open} onClose={props.onClose}>
      <Grid container direction="column">
        <Grid item xs align="right" style={{ minWidth: "250px" }}>
          <IconButton onClick={props.onClose}>
            <CloseIcon style={{ fontSize: "40px" }} />
          </IconButton>
        </Grid>
        <Grid item xs>
          <MenuList>{content}</MenuList>
        </Grid>
      </Grid>
    </Drawer>
  );
}
