import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import URL from "../constants/Urls";
import COL from "../constants/Colors";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { SERVER } from "../constants/Config";

function Login({ t }) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const handleLogin = () => {
    axios
      .post(SERVER + "loginadmin", {
        user: email,
        pass: pass,
      })
      .then((res) => {
        if (res.data.status === "ok") {
          localStorage.setItem('admintoken', res.data.token)
          history.push("/admin/pages")
        } else {
          throw new Error("adminloginerror");
        }
      })
      .catch((err) => {
        alert(t("logginError"));
        setEmail("");
        setPass("");
      });
  };

  return (
    <Container maxWidth="lg">
      <Card
        id="mobileView"
        style={{
          boxShadow: "0  18px 60px 0 rgba(15, 33, 37, 0.2)",
          padding: 50,
          borderRadius: 0,
        }}
      >
        <CardContent>
          <CardMedia style={{ marginBottom: 10 }}>
            <img
              src={URL.logoD}
              style={{ width: 300 }}
              alt="aaron wasserette"
            />
          </CardMedia>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="email"
                    onChange={(event) => setEmail(event.target.value)}
                    name="email"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t("password")}
                    onChange={(event) => setPass(event.target.value)}
                    name="password"
                    variant="outlined"
                    type="password"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                onClick={handleLogin}
                style={{
                  padding: "15px 30px",
                  backgroundColor: COL.bottunColor,
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                  borderRadius: 25,
                }}
              >
                Inloggen
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "left", marginBottom: "20px" }}
            ></Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function Admin(props) {
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = t("aboutusmetatile");
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div>
      <Grid
        container
        direction="column"
        align="center"
        wrap="nowrap"
        style={{ backgroundColor: "white" }}
      >
        <Grid item xs={12} style={{ marginTop: 50, zIndex: 2 }}>
          <Login t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
