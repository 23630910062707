import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Services from "./components/pages/Services";
import Work from "./components/pages/Works";
import Products from "./components/pages/Products";
import ShoppingCart from "./components/pages/ShoppingCart";
import COL from "./components/constants/Colors";
import StomerijService from "./components/pages/StomerijService";
import WasseretteService from "./components/pages/WasseretteService";
import Zakelijk from "./components/pages/Zakelijk";
import Kledingreparatie from "./components/pages/Kledingreparatie";
import Tapijtreiniging from "./components/pages/Tapijtreiniging";
import Wasabonnementen from "./components/pages/Wasabonnementen";
import ContactUS from "./components/pages/ContactOpnemen";
import Login from "./components/pages/Login";
import Afrekenen from "./components/pages/Afrekenen";
import PasswordForget from "./components/pages/PasswordForget";
import ResetPassword from "./components/pages/ResetPassword";
import LocationRijswijk from "./components/pages/LocationRijswijk";
import LocationDenhaag from "./components/pages/LocationDenhaag";
import OrderStatus from "./components/pages/OrderStatus";
import AccountSetting from "./components/pages/AccountSetting";
import DekbedStomen from "./components/pages/DekbedStomen";
import Gordijnen from "./components/pages/Gordijnen";
import Jas from "./components/pages/Jas";
import Overhemden from "./components/pages/Overhemden";
import Colbert from "./components/pages/Colbert";
import Jurken from "./components/pages/Jurken";
import ServicesCities from "./components/pages/ServicesCities";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import ProductDashboard from "./components/pages/dashboard/ProductDashboard";
import Admin from "./components/pages/Admin";
import EditCouponCode from "./components/pages/dashboard/EditCouponCode";
import DashboardPages from "./components/pages/dashboard/DashboardPages";
import AddCouponCode from "./components/pages/dashboard/AddCouponCode";

/**
 * Start with homepage of the application
 * and includes  routes to other pages.
 */
class App extends Component {
  /**
   * Renders the application, including
   * the routes to the other indivual pages.
   */
  render() {
    return (
      <div style={{ backgroundColor: COL.backgroundColor }}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/en/"
              render={(props) => <Home en={true} {...props} />}
            />
            <Route exact path="/over-ons/" component={About} />
            <Route exact path="/admin/" component={Admin} />

            <Route
              exact
              path="/en/about-us/"
              render={(props) => <About en={true} {...props} />}
            />
            <Route
              exact
              path="/over-ons/locatie-rijswijk/"
              component={LocationRijswijk}
            />
            <Route
              exact
              path="/en/about-us/location-rijswijk/"
              render={(props) => <LocationRijswijk en={true} {...props} />}
            />
            <Route
              exact
              path="/over-ons/locatie-den-haag/"
              component={LocationDenhaag}
            />
            <Route
              exact
              path="/en/about-us/location-den-haag/"
              render={(props) => <LocationDenhaag en={true} {...props} />}
            />
            <Route exact path="/hoe-werkt-het/" component={Work} />
            <Route
              exact
              path="/en/how-it-works/"
              render={(props) => <Work en={true} {...props} />}
            />
            <Route exact path="/diensten/" component={Services} />
            <Route
              exact
              path="/en/services/"
              render={(props) => <Services en={true} {...props} />}
            />
            <Route exact path="/bestellen/" component={Products} />
            <Route
              exact
              path="/en/order/"
              render={(props) => <Products en={true} {...props} />}
            />
            <Route exact path="/wasmand/" component={ShoppingCart} />
            <Route
              exact
              path="/en/cart/"
              render={(props) => <ShoppingCart en={true} {...props} />}
            />
            <Route exact path="/contact/" component={ContactUS} />
            <Route
              exact
              path="/en/contact/"
              render={(props) => <ContactUS en={true} {...props} />}
            />
            <Route exact path="/mijn-account/" component={Login} />
            <Route
              exact
              path="/en/my-account/"
              render={(props) => <Login en={true} {...props} />}
            />
            <Route
              exact
              path="/mijn-account/loggedin/"
              component={AccountSetting}
            />
            <Route
              exact
              path="/en/my-account/loggedin/"
              render={(props) => <AccountSetting en={true} {...props} />}
            />
            <Route
              exact
              path="/mijn-account/lost-password/"
              component={PasswordForget}
            />
            <Route
              exact
              path="/en/my-account/lost-password/"
              render={(props) => <PasswordForget en={true} {...props} />}
            />
            <Route exact path="/wasmand/afrekenen/" component={Afrekenen} />

            <Route
              exact
              path="/Algemene-Voorwaarden/"
              component={TermsAndConditions}
            />
            <Route
              exact
              path="/en/temrs-and-conditions/"
              render={(props) => <TermsAndConditions en={true} {...props} />}
            />

            <Route
              exact
              path="/en/checkout/"
              render={(props) => <Afrekenen en={true} {...props} />}
            />
            <Route
              exact
              path="/mijn-account/resetpassword/"
              component={ResetPassword}
            />
            <Route
              exact
              path="/en/my-account/resetpassword/"
              render={(props) => <ResetPassword en={true} {...props} />}
            />
            <Route
              exact
              path="/diensten/stomerij/"
              component={StomerijService}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/"
              render={(props) => <StomerijService en={true} {...props} />}
            />
            <Route
              exact
              path="/diensten/wasserette/"
              component={WasseretteService}
            />
            <Route
              exact
              path="/en/services/laundromat/"
              render={(props) => <WasseretteService en={true} {...props} />}
            />
            <Route
              exact
              path="/diensten/zakelijk-stomerij-wasserette/"
              component={Zakelijk}
            />
            <Route
              exact
              path="/en/dry-cleaning-for-businesses/"
              render={(props) => <Zakelijk en={true} {...props} />}
            />
            <Route
              exact
              path="/diensten/kledingreparatie/"
              component={Kledingreparatie}
            />
            <Route
              exact
              path="/en/services/clothing-repair/"
              render={(props) => <Kledingreparatie en={true} {...props} />}
            />
            <Route
              exact
              path="/diensten/tapijtreiniging/"
              component={Tapijtreiniging}
            />
            <Route
              exact
              path="/en/services/carpet-dry-cleaning/"
              render={(props) => <Tapijtreiniging en={true} {...props} />}
            />
            <Route
              exact
              path="/diensten/wasabonnementen/"
              component={Wasabonnementen}
            />
            <Route
              exact
              path="/en/services/laundry-subscriptions/"
              render={(props) => <Wasabonnementen en={true} {...props} />}
            />
            <Route exact path="/orderstatus/" component={OrderStatus} />
            <Route exact path="/myorder/" component={OrderStatus} />
            <Route
              exact
              path="/en/orderstatus/"
              render={(props) => <OrderStatus en={true} {...props} />}
            />
            <Route exact path="/hallo-wereld/" component={Home} />
            <Route
              exact
              path="/diensten/stomerij/dekbed-stomen/"
              component={DekbedStomen}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/dekbed-stomen/"
              render={(props) => <DekbedStomen en={true} {...props} />}
            />
            <Route
              exact
              path="/diensten/stomerij/gordijnen-stomen/"
              component={Gordijnen}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/dry-cleaning-curtains/"
              render={(props) => <Gordijnen en={true} {...props} />}
            />
            <Route
              exact
              path="/diensten/stomerij/jas-stomen/"
              component={Jas}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/coat-dry-cleaning/"
              render={(props) => <Jas en={true} {...props} />}
            />
            <Route
              exact
              path="/diensten/stomerij/overhemd-stomen/"
              component={Overhemden}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/shirt-dry-cleaning/"
              render={(props) => <Overhemden en={true} {...props} />}
            />

            <Route
              exact
              path="/diensten/stomerij/colbert-stomen/"
              component={Colbert}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/blazer-dry-cleaning/"
              render={(props) => <Colbert en={true} {...props} />}
            />

            <Route exact path="/product/bruidsjurk/" component={Jurken} />
            <Route
              exact
              path="/en/product/wedding-gown/"
              render={(props) => <Jurken en={true} {...props} />}
            />

            <Route exact path="/winkel/" component={Products} />

            <Route
              exact
              path="/diensten/stomerij/leidschenveen/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Leidschenveen"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/leidschenveen/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Leidschenveen"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/maasland/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Maasland"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/maasland/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Maasland"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/midden-delfland/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Midden-Delfland"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/midden-delfland/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"midden-delfland"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/monster/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Monster"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/monster/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Monster"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/naaldwijk/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Naaldwijk"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/naaldwijk/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Naaldwijk"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/nootdorp/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Nootdorp"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/nootdorp/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Nootdorp"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/pijnacker/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Pijnacker"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/pijnacker/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Pijnacker"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/poeldijk/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Poeldijk"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/poeldijk/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Poeldijk"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/rijswijk/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Rijswijk"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/rijswijk/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Rijswijk"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/scheveningen/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"scheveningen"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/scheveningen/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"scheveningen"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/voorburg/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Voorburg"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/voorburg/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Voorburg"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/wassenaar/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Wassenaar"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/wassenaar/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Wassenaar"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/wateringen/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Wateringen"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/wateringen/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Wateringen"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/westland/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Westland"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/westland/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Westland"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/ypenburg/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Ypenburg"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/ypenburg/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Ypenburg"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/zoetermeer/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Zoetermeer"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/zoetermeer/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Zoetermeer"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/stomerij-leidschendam/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Leidschendam"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/leidschendam/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Leidschendam"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/de-lier/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"De Lier"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/de-lier/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"De Lier"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/delft/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Delft"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/delft/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Delft"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/den-haag/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Den Haag"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/den-haag/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Den Haag"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/den-hoorn/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Den Hoorn"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/den-hoorn/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Den Hoorn"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/duindorp/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Duindorp"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/duindorp/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Duindorp"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/honselersdijk/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Honselersdijk"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/honselersdijk/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Honselersdijk"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/kijkduin/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Kijkduin"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/kijkduin/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Kijkduin"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/leiden/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Leiden"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/leiden/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Leiden"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/zoetermeer/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Zoetermeer"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/zoetermeer/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Zoetermeer"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/leiden/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Leiden"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/leiden/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Leiden"}
                  {...props}
                  en={true}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/dry-cleaning-leiden/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Leiden"}
                  en={true}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/den-haag/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Den Haag"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/den-haag/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Den Haag"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/delft/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Delft"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/delft/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Delft"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/rijswijk/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Rijswijk"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/rijswijk/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Rijswijk"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/scheveningen/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Scheveningen"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/scheveningen/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Scheveningen"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/voorburg/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Voorburg"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/voorburg/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Voorburg"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/leidschendam/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Leidschendam"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/leidschendam/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Leidschendam"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/naaldwijk/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Naaldwijk"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/naaldwijk/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Naaldwijk"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/wassenaar/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Wassenaar"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/wassenaar/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Wassenaar"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/nootdorp/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Nootdorp"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/nootdorp/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Nootdorp"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/pijnacker/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Pijnacker"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/pijnacker/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Pijnacker"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/westland/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Westland"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/westland/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Westland"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/wasserette/ypenburg/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Ypenburg"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/ypenburg/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Ypenburg"}
                  {...props}
                  en={true}
                />
              )}
            />

            <Route
              exact
              path="/diensten/stomerij/pak-stomen/"
              component={Home}
            />

            <Route exact path="/en/shop/" component={Home} />
            <Route
              exact
              path="/en/services/dry-cleaning/dry-cleaning-delft/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Delft"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/dry-cleaning-in-the-hague/"
              render={(props) => (
                <ServicesCities
                  service={"stomerij"}
                  city={"Den Haag"}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/en/services/laundromat/laundry-service-the-hague/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"The Hague"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/laundry-service-delft/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Delft"}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/en/services/laundromat/laundry-service-leiden/"
              render={(props) => (
                <ServicesCities
                  service={"wasserette"}
                  city={"Leiden"}
                  {...props}
                />
              )}
            />
            <Route exact path="/bedankt/" component={OrderStatus} />
            <Route exact path="/en/thanks/" component={OrderStatus} />

            <Route
              exact
              path="/en/services/dry-cleaning/suit-dry-cleaning/"
              component={Home}
            />
            <Route
              exact
              path="/en/services/dry-cleaning/wedding-dress-dry-cleaning/"
              component={Home}
            />
            <Route exact path="/diensten-test/" component={Home} />
            <Route
              exact
              path="/category/geen-onderdeel-van-een-categorie/"
              component={Home}
            />
            <Route exact path="/author/admin/" component={Home} />
            <Route
              exact
              path="/en/product-categorie/dry-cleaning/"
              component={Home}
            />
            <Route exact path="/product-categorie/stomen/" component={Home} />
            <Route
              exact
              path="/product-categorie/stomen/aanbiedingen/"
              component={Home}
            />
            <Route
              exact
              path="/en/product-categorie/dry-cleaning/special-offers/"
              component={Home}
            />
            <Route
              exact
              path="/en/product-categorie/dry-cleaning/laundry-service/"
              component={Home}
            />
            <Route
              exact
              path="/product-categorie/stomen/wassen/"
              component={Home}
            />
            <Route
              exact
              path="/product-categorie/stomen/overhemd-tops/"
              component={Home}
            />
            <Route
              exact
              path="/en/product-categorie/dry-cleaning/shirts-tops/"
              component={Home}
            />
            <Route
              exact
              path="/product-categorie/stomen/broeken/"
              component={Home}
            />
            <Route
              exact
              path="/en/product-categorie/dry-cleaning/pants/"
              component={Home}
            />
            <Route
              exact
              path="/en/product-categorie/dry-cleaning/coats/"
              component={Home}
            />
            <Route
              exact
              path="/product-categorie/stomen/jassen/"
              component={Home}
            />
            <Route
              exact
              path="/en/product-categorie/dry-cleaning/dresses/"
              component={Home}
            />
            <Route
              exact
              path="/product-categorie/stomen/jurken/"
              component={Home}
            />

            <Route
              exact
              path="/diensten/stomerij/trouwjurk-stomen/"
              component={Home}
            />

            <Route
              exact
              path="/en/product-categorie/dry-cleaning/bedroom-textile/"
              component={Home}
            />
            <Route
              exact
              path="/product-categorie/stomen/slaapkamer-textiel/"
              component={Home}
            />
            <Route
              exact
              path="/en/product-categorie/dry-cleaning/home-textiles/"
              component={Home}
            />
            <Route
              exact
              path="/product-categorie/stomen/woningtextiel/"
              component={Home}
            />
            <Route
              exact
              path="/en/product-categorie/dry-cleaning/curtains/"
              component={Home}
            />
            <Route
              exact
              path="/product-categorie/stomen/gordijnen/"
              component={Home}
            />
            <Route
              exact
              path="/en/product-categorie/dry-cleaning/leather/"
              component={Home}
            />
            <Route
              exact
              path="/product-categorie/stomen/leder/"
              component={Home}
            />
            <Route
              exact
              path="/admin/dashboard/"
              component={ProductDashboard}
            />
            <Route
              exact
              path="/admin/codes/editCoupon/"
              component={EditCouponCode}
            />
            <Route
              exact
              path="/admin/pages/"
              component={DashboardPages}
            />
            <Route
              exact
              path="/admin/codes/addcoupon/"
              component={AddCouponCode}
            />

            <Route
              exact
              path="*"
              component={() => (
                <h1 style={{ textAlign: "center" }}>404 PAGE NOT FOUND</h1>
              )}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
