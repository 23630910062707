import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import Logo from "../layout/Logo";
import { useTranslation } from "react-i18next";
import SideBar from "../layout/SideBar";
import ProductsList from "./ProductsList";
import Reviews from "../layout/Reviews";
import SiteMap from "../layout/SiteMap";
import BackgroundColor from "../layout/Backgroundcolor";
import ResponsiveMenu from "../layout/ResponsiveMenu";
import TopDwonBar from "../layout/TopDwonBar";
import Container from "@material-ui/core/Container";

function backgroundText(t) {
  return (
    <div>
      <h2 style={{ fontFamily: "Poppins, sans-serif" }}>
        {t("bestellingTitle1")}
      </h2>
      <h4 style={{ fontFamily: "Poppins, sans-serif" }}>
        {t("bestellingTitle2")}
      </h4>
    </div>
  );
}

export default function Products(props) {
  const [drawer, setDrawer] = useState(false);
  const [drawer2, setDrawer2] = useState(false);
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("pricepagetitle");
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"prices"}
      />
      <TopDwonBar open={drawer2} onClose={() => setDrawer2(false)} />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"prices"} openDrawer={setDrawer} />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <BackgroundColor text={backgroundText(t)} />
        </Grid>
        <Grid item x={12}>
          <Container maxWidth="lg">
            <Grid container direction="row">
              <Grid item xs={12} md={3} style={{ marginTop: "40px" }}>
                <ResponsiveMenu openDrawer={setDrawer2} />
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                style={{ marginTop: "15px" }}
                id="mobileproductlistid"
              >
                <ProductsList />
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "white",
            marginTop: "20px",
            paddingBottom: "60px",
            zIndex: 10,
          }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12} className="gradientBG" style={{ zIndex: 10 }}>
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
