import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// {
//   status: 'idle' | 'loading' | 'succeeded' | 'failed',
//   error: string | null
// }

import {
  fetchProducts,
  selectAllProducts,
  selectCategories,
} from "../features/reducers/productsSlice";

export default function useProducts() {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.products.status);
  const error = useSelector((state) => state.products.error);
  const products = useSelector(selectAllProducts);
  const categories = useSelector(selectCategories);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchProducts());
    }
  }, [status, dispatch]);

  return { status, products, categories, error };
}
