import React from "react";
import "../../css/styles.css";

export default function BackgroundImg(props) {
  let root = document.documentElement;
  root.style.setProperty("--homeUrl", `url('${props.url}')`);
  return (
    <div className="back-image">
      <div className="imgOverlay">
        <div className="title-text">{props.text}</div>
      </div>
    </div>
  );
}
