import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import COL from "../constants/Colors";
import Logo from "../layout/Logo";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { theme } from "../constants/Config";
import BestelEenvoudig from "../layout/BestelEenvoudig";
import Reviews from "../layout/Reviews";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core/styles";
import SiteMap from "../layout/SiteMap";
import SideBar from "../layout/SideBar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";

function Ourservices({ t }) {
  return (
    <ThemeProvider theme={theme}>
      <Typography
        variant="h2"
        style={{
          textAlign: "center",
          color: COL.titleHeader,
          fontFamily: "Poppins, sans-serif",
          marginTop: "50px",
        }}
      >
        <b> {t("OurSerives")}</b>
      </Typography>
      <Container maxWidth="lg">
        <Typography
          variant="body1"
          style={{
            textAlign: "center",
            color: COL.textColor,
            marginTop: "10px",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          <b>{t("OurSerivesText")}</b>
        </Typography>
      </Container>
    </ThemeProvider>
  );
}

function OurservicesBox({ t }) {
  const history = useHistory();

  return (
    <Container maxWidth="lg">
      <Grid container direction="column" wrap="nowrap">
        <Grid item xs>
          <Grid
            container
            spacing={3}
            style={{ flexGrow: 1, marginTop: "50px" }}
          >
            <Grid item xs={12} md={4}>
              <Card align="centre" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    variant="h3"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: "black",
                    }}
                  >
                    <Box fontWeight="fontWeightBold" m={1}>
                      {t("LaundryServices1")}
                    </Box>
                  </Typography>
                  <p
                    style={{
                      color: COL.textColor,
                      marginLeft: "25px",
                      marginRight: "25px",
                      letterSpacing: "0.5px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {t("LaundryServicesText1")}
                  </p>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => history.push("/diensten/stomerij/")}
                    style={{
                      backgroundColor: COL.orangeBold,
                      color: "#ffffff",
                      borderRadius: 25,
                      width: "150px",
                      height: "50px",
                    }}
                  >
                    <Typography
                      style={{ fontFamily: "Poppins, sans-serif" }}
                      variant="body1"
                    >
                      {t("readmore")}
                    </Typography>
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card align="centre" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: "black",
                    }}
                    variant="h3"
                  >
                    <Box fontWeight="fontWeightBold" m={1}>
                      {t("LaundryServices2")}
                    </Box>
                  </Typography>
                  <p
                    style={{
                      color: COL.textColor,
                      fontFamily: "Poppins, sans-serif",
                      marginLeft: "25px",
                      marginRight: "25px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {t("LaundryServicesText2")}
                  </p>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => history.push("/diensten/wasserette/")}
                    style={{
                      backgroundColor: COL.orangeBold,
                      color: "#ffffff",
                      borderRadius: 25,
                      width: "150px",
                      height: "50px",
                    }}
                  >
                    <Typography
                      style={{ fontFamily: "Poppins, sans-serif" }}
                      variant="body1"
                    >
                      {t("readmore")}
                    </Typography>
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card align="centre" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: "black",
                    }}
                    variant="h3"
                  >
                    <Box fontWeight="fontWeightBold" m={1}>
                      {t("LaundryServices3")}
                    </Box>
                  </Typography>
                  <p
                    style={{
                      color: COL.textColor,
                      fontFamily: "Poppins, sans-serif",
                      marginLeft: "25px",
                      marginRight: "25px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {t("LaundryServicesText3")}
                  </p>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() =>
                      history.push("/diensten/zakelijk-stomerij-wasserette/")
                    }
                    style={{
                      backgroundColor: COL.orangeBold,
                      color: "#ffffff",
                      borderRadius: 25,
                      width: "150px",
                      height: "50px",
                    }}
                  >
                    <Typography
                      style={{ fontFamily: "Poppins, sans-serif" }}
                      variant="body1"
                    >
                      {t("readmore")}
                    </Typography>
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card align="centre" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: "black",
                    }}
                    variant="h3"
                  >
                    <Box fontWeight="fontWeightBold" m={1}>
                      {t("LaundryServices4")}
                    </Box>
                  </Typography>
                  <p
                    style={{
                      color: COL.textColor,
                      fontFamily: "Poppins, sans-serif",
                      marginLeft: "25px",
                      marginRight: "25px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {t("LaundryServicesText4")}
                  </p>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => history.push("/diensten/wasabonnementen/")}
                    style={{
                      backgroundColor: COL.orangeBold,
                      color: "#ffffff",
                      borderRadius: 25,
                      width: "150px",
                      height: "50px",
                    }}
                  >
                    <Typography
                      style={{ fontFamily: "Poppins, sans-serif" }}
                      variant="body1"
                    >
                      {t("readmore")}
                    </Typography>
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card align="centre" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: "black",
                    }}
                    variant="h3"
                  >
                    <Box fontWeight="fontWeightBold" m={1}>
                      {t("LaundryServices5")}
                    </Box>
                  </Typography>
                  <p
                    style={{
                      color: COL.textColor,
                      fontFamily: "Poppins, sans-serif",
                      marginLeft: "25px",
                      marginRight: "25px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {t("LaundryServicesText5")}
                  </p>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => history.push("/diensten/kledingreparatie/")}
                    style={{
                      backgroundColor: COL.orangeBold,
                      color: "#ffffff",
                      borderRadius: 25,
                      width: "150px",
                      height: "50px",
                    }}
                  >
                    <Typography
                      style={{ fontFamily: "Poppins, sans-serif" }}
                      variant="body1"
                    >
                      {t("readmore")}
                    </Typography>
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card align="centre" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: "black",
                    }}
                    variant="h3"
                  >
                    <Box fontWeight="fontWeightBold" m={1}>
                      {t("LaundryServices6")}
                    </Box>
                  </Typography>
                  <p
                    style={{
                      color: COL.textColor,
                      fontFamily: "Poppins, sans-serif",
                      marginLeft: "25px",
                      marginRight: "25px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {t("LaundryServicesText6")}
                  </p>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => history.push("/diensten/tapijtreiniging/")}
                    style={{
                      backgroundColor: COL.orangeBold,
                      color: "#ffffff",
                      borderRadius: 25,
                      width: "150px",
                      height: "50px",
                    }}
                  >
                    <Typography
                      style={{ fontFamily: "Poppins, sans-serif" }}
                      variant="body1"
                    >
                      {t("readmore")}
                    </Typography>
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default function Services(props) {
  const [drawer, setDrawer] = useState(false);
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("servicesmetatitle");
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"services"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        {/* Sticky NavBat */}
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"services"} openDrawer={setDrawer} />
        </Grid>
        {/* Ourservices */}
        <Grid item xs={12} style={{ zIndex: 2 }}>
          <Ourservices t={t} />
        </Grid>
        {/* Ourservicesbox */}
        <Grid item xs={12} style={{ zIndex: 2 }}>
          <OurservicesBox t={t} />
        </Grid>
        {/* Banner */}
        <Grid
          item
          xs={12}
          style={{ marginTop: "75px", backgroundColor: COL.orange }}
        >
          <BestelEenvoudig />
        </Grid>
        {/* Reviews */}
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", paddingBottom: "60px" }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12} className="gradientBG">
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
