import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER } from "../../components/constants/Config";

const initialState = {
  data: {},
  status: "idle",
  error: null,
};

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async () => {
    const response = await axios.post(SERVER + "products", {});
    return response.data;
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProducts.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
    [fetchProducts.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const selectCategories = (state) => {
  var cat = [];
  for (const [category, value] of Object.entries(state.products.data)) {
    cat.push({
      icon: value.icon,
      description: value.description,
      name: category,
      active: value.active,
      products: Object.keys(value.products),
    });
  }

  return cat;
};

export const selectAllProducts = (state) => {
  var res = {};

  for (const [category, value] of Object.entries(state.products.data)) {
    for (const [pid, details] of Object.entries(value.products)) {
      if (
        state.products.data[category].active.toString().toLowerCase() === 'no'
      ) {
        res[pid] = {...details, active: 'no'};
      } else {
        res[pid] = details;
      }
    }
  }

  return res;
};

export default productsSlice.reducer;
