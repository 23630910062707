import React from "react";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useNavScroll from "../../features/useNavScroll";
import Typography from "@material-ui/core/Typography";
import useProducts from "../../features/useProducts";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { useTheme } from "@material-ui/core/styles";
import COL from "../constants/Colors";

const useStyles = makeStyles(() => ({
  paper: {
    width: "100%",
    paddingBottom: 20,
  },
  titleSection: {
    fontWeight: "bold",
    padding: 20,
    // marginLeft: "-20px",
    backgroundColor: COL.productListTItle,
    fontFamily: "Poppins, sans-serif",
    // paddingTop: 10,
    color: "white",
  },
  menuItem: {
    borderBottom: "solid 1px #f7f6fb",
    fontFamily: "Poppins, sans-serif",
    width: 200,
  },
  menuShopItems: {
    width: "100%",
  },
}));

function DesktopView(props) {
  const [t] = useTranslation();
  const classes = useStyles();
  const data = useProducts();

  let content;
  content = [];
  for (const cat of data.categories) {
    let url = "#" + cat.name;

    content.push(
      <a href={url} key={t(cat.name)} id={t(cat.name)}>
        <MenuItem
          id={t(cat.name)}
          key={t(cat.name)}
          className={classes.menuItem}
        >
          {t(cat.name)}
        </MenuItem>
      </a>
    );
  }

  return (
    <div
      id="productsmenu"
      className={props.scrolled ? "stickyLeftMenu" : "desktopMenu"}
    >
      <Grid container justify="space-between">
        <Grid item>
          <div>
            <Paper className={classes.paper} elevation={0}>
              <Typography className={classes.titleSection}>
                {t("makeachoice")}
              </Typography>
              <MenuList>{content}</MenuList>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function MobileView(props) {
  const [t] = useTranslation();
  return (
    <div
      id="productsmenu"
      className={props.scrolled ? "mobileMenu stickyShopMenu" : "mobileMenu"}
    >
      <Grid
        container
        onClick={() => props.openDrawer(true)}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={9} style={{ paddingLeft: 20 }}>
          <Typography
            style={{
              fontFamily: "Poppins, sans-serif",
              marginLeft: "20px",
              marginBottom: "20px",
              color: "white",
            }}
          >
            {t("makeachoice")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <IconButton
            aria-label="more"
            style={{ transform: "scale(2)", marginTop: -20 }}
          >
            <KeyboardArrowUp style={{ color: "white" }} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default function ResponsiveMenu(props) {
  const scrolled = useNavScroll("productsmenu");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("md"));

  if (!isMobile) return <MobileView {...props} scrolled={scrolled} />;
  return <DesktopView {...props} scrolled={scrolled} />;
}
