import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import BackgroundImg from "../layout/BackgroundImg";
import URL from "../constants/Urls";
import COL from "../constants/Colors";
import Logo from "../layout/Logo";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { theme } from "../constants/Config";
import BestelEenvoudig from "../layout/BestelEenvoudig";
import Reviews from "../layout/Reviews";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core/styles";
import SiteMap from "../layout/SiteMap";
import SideBar from "../layout/SideBar";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import { useHistory } from "react-router-dom";

function WasabonnementenInfo({ t }) {
  const history = useHistory();

  return (
    <Container maxWidth="lg">
      <Card
        id="mobileView"
        style={{
          boxShadow: "0 18px 60px 0 rgba(15, 33, 37, 0.2)",
          padding: 50,
          borderRadius: 0,
        }}
      >
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h3"
                  style={{
                    textAlign: "left",
                    color: COL.titleHeader,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "10px",
                    // fontSize: 45,
                    fontWeight: 600,
                  }}
                >
                  {t("WasAbonnementenTitle1")}
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    textAlign: "left",
                    color: COL.textColor,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "10px",
                    // fontSize: 16,
                    fontWeight: 100,
                  }}
                >
                  {t("WasAbonnementenParagrapgh1")}
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    textAlign: "left",
                    color: COL.textColor,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "10px",
                    // fontSize: 16,
                    fontWeight: 100,
                  }}
                >
                  {t("WasabonnementBoldText")}
                  {t(" ")}
                  {t("WasAbonnementenParagrapgh2")}
                </Typography>

                <Typography
                  variant="h3"
                  style={{
                    textAlign: "left",
                    color: COL.titleHeader,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "20px",
                    // fontSize: 45,
                    fontWeight: "bold",
                  }}
                >
                  {t("WasAbonnementenTitle2")}
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    textAlign: "left",
                    color: COL.textColor,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "10px",
                    // fontSize: 16,
                    fontWeight: 100,
                  }}
                >
                  {t("Stap1.")}
                  {t(" ")}
                  {t("stap1text")}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    textAlign: "left",
                    color: COL.textColor,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "10px",
                    // fontSize: 16,
                    fontWeight: 100,
                  }}
                >
                  {t("Stap2.")}
                  {t(" ")}
                  {t("stap2text")}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    textAlign: "left",
                    color: COL.textColor,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "10px",
                    // fontSize: 16,
                    fontWeight: 100,
                  }}
                >
                  {t("Stap3.")}
                  {t(" ")}
                  {t("stap3text")}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    textAlign: "left",
                    color: COL.textColor,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "10px",
                    // fontSize: 16,
                    fontWeight: 100,
                  }}
                >
                  {t("Stap4.")}
                  {t(" ")}
                  {t("stap4text")}
                  {t(" ")}
                  {t("inside24HoursText")}
                  {t(" ")}
                  {t("WasAbonnementenParagrapgh3")}
                </Typography>

                <Typography
                  variant="body1"
                  style={{
                    textAlign: "left",
                    color: COL.textColor,
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "10px",
                    // fontSize: 16,
                    fontWeight: 100,
                  }}
                >
                  {t("WasAbonnementenParagrapgh4")}
                </Typography>
                <Button
                  variant="contained"
                  // color="primary"
                  onClick={() => history.push("/contact/")}
                  style={{
                    background: COL.bottunColor,
                    fontFamily: "Poppins, sans-serif",
                    color: "white",
                    borderRadius: 25,
                    padding: "15px 30px",
                    marginTop: "20px",
                    float: "left",
                    // fontSizse: 16,
                    fontWeight: 100,
                  }}
                >
                  {t("contactButton")}
                </Button>
              </ThemeProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CardMedia align="right">
                <img
                  src={URL.wasabonimag}
                  style={{ width: 300 }}
                  alt="wasabonnement"
                  id="imgwasabonemobileview"
                />
              </CardMedia>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function Wasabonnementen(props) {
  const [drawer, setDrawer] = useState(false);
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = t("wasabonepagetitle");
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"services"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        {/* Sticky NavBat */}
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"services"} openDrawer={setDrawer} />
        </Grid>
        {/* picture */}
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <BackgroundImg url={URL.wasimg} />
        </Grid>
        {/* AboutUS */}
        <Grid item xs={12} style={{ marginTop: "-160px", zIndex: 2 }}>
          <WasabonnementenInfo t={t} />
        </Grid>
        {/* Banner */}
        <Grid
          item
          xs={12}
          style={{ marginTop: "75px", backgroundColor: COL.orange }}
        >
          <BestelEenvoudig />
        </Grid>
        {/* Reviews */}
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", paddingBottom: "60px" }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12} className="gradientBG">
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
