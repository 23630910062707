import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import NavItems from "./NavItems";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import Link from "@material-ui/core/Link";
import URL from "../constants/Urls";
import { useHistory } from "react-router-dom";

export default function SideBar(props) {
  const [t, i18n] = useTranslation();
  const history = useHistory();

  const linkStyles = {
    padding: "10px 0px 0px 20px",
    fontFamily: "Poppins, sans-serif",
  };
  return (
    <Drawer anchor="left" open={props.open} onClose={props.onClose}>
      <Grid container direction="column">
        <Grid item xs align="right" style={{ minWidth: "250px" }}>
          <IconButton onClick={props.onClose}>
            <CloseIcon style={{ fontSize: "40px" }} />
          </IconButton>
        </Grid>
        <Grid item xs>
          <NavItems orientation="vertical" currentPage={props.currentPage} />
        </Grid>
        <Grid item xs>
          <Divider style={{ margin: "10px 0px 10px 0px" }} />
        </Grid>
        <Grid item xs>
          <Link
            component="button"
            style={linkStyles}
            variant="body2"
            onClick={() => history.push("/contact/")}
          >
            {t("contact")}
          </Link>
        </Grid>
        <Grid item xs>
          <Link
            component="button"
            style={linkStyles}
            variant="body2"
            onClick={() => history.push("/mijn-account/")}
          >
            {t("myaccount")}
          </Link>
        </Grid>
        <Grid item xs style={linkStyles}>
          <IconButton onClick={() => i18n.changeLanguage("nl")}>
            <img src={URL.nlFlag} alt="nl" />
          </IconButton>
          <IconButton onClick={() => i18n.changeLanguage("en")}>
            <img src={URL.enFlag} alt="en" />
          </IconButton>
        </Grid>
      </Grid>
    </Drawer>
  );
}
