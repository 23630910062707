import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    tel: '',
    street: '',
    house_number: '',
    postcode: '',
    city: '',
    paymentmethod: {
      id: '',
      name: '',
      icon: '',
    },
    token: '',
    lang: '',
  },
  status: 'idle',
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userAdded(state, action) {
      state.data.id = action.payload.id;
      state.data.firstname = action.payload.firstname;
      state.data.lastname = action.payload.lastname;
      state.data.token = action.payload.token;
      state.data.email = action.payload.email;
      state.data.tel = action.payload.tel;
      state.data.street = action.payload.street;
      state.data.house_number = action.payload.house_number;
      state.data.postcode = action.payload.postcode;
      state.data.city = action.payload.city;
    },
    userRemoved(state, action) {
      state.data.id = '';
      state.data.firstname = '';
      state.data.lastname = '';
      state.data.token = '';
      state.data.email = '';
      state.data.tel = '';
      state.data.street = '';
      state.data.house_number = '';
      state.data.postcode = '';
      state.data.city = '';
    },
    paymentmethodAdded(state, action) {
      state.data.paymentmethod = action.payload.paymentmethod;
    },
    changeLang(state, action) {
      state.data.lang = action.payload.lang;
    },
  },
});

export const {
  userAdded,
  userRemoved,
  paymentmethodAdded,
  changeLang,
} = userSlice.actions;

export const selectUserInfo = (state) => state.user.data;
export const selectPaymentMethod = (state) => state.user.data.paymentmethod;
export const isUserLoggedIn = (state) => {
  if (Number(state.user.data.id) > 0) {
    return true;
  } else {
    return false;
  }
};

export default userSlice.reducer;
