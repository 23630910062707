import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import BackgroundImg from "../layout/BackgroundImg";
import URL from "../constants/Urls";
import COL from "../constants/Colors";
import Logo from "../layout/Logo";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import HowDoesItWork from "../layout/HowDoesItWork";
import TextPhotoBanner from "../layout/TextPhotoBanner";
import { theme } from "../constants/Config";
import BestelEenvoudig from "../layout/BestelEenvoudig";
import Reviews from "../layout/Reviews";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core/styles";
import SiteMap from "../layout/SiteMap";
import SideBar from "../layout/SideBar";

function MobileApplicationCard({ t }) {
  return (
    <Container maxWidth="lg">
      <Card
        style={{
          boxShadow: "0 18px 60px 0 rgba(15, 33, 37, 0.2)",
        }}
      >
        <CardContent>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={12} sm={12} md={4}>
              <Grid item xs>
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h3"
                    style={{
                      color: COL.logo,
                      marginTop: "10px",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    {t("downloadtheapp")}
                  </Typography>
                </ThemeProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12} sm={6} md={6}>
                  <a href={URL.applestoreapp}>
                    <img className="imgapps" src={URL.ablue} alt="applestore" />
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <a href={URL.googleplayapp}>
                    <img className="imgapps" src={URL.gblue} alt="googleplay" />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

function backgroundText(t) {
  return (
    <div>
      <h2 style={{ fontFamily: "Poppins, sans-serif" }}>{t("hometitle1")}</h2>
      <h4 style={{ fontFamily: "Poppins, sans-serif" }}>{t("hometitle2")}</h4>
    </div>
  );
}

export default function Home(props) {
  const [t, i18n] = useTranslation();
  const [drawer, setDrawer] = useState(false);
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("homeTitle");
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"home"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"home"} openDrawer={setDrawer} />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <BackgroundImg url={URL.homeBackground} text={backgroundText(t)} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "-60px", zIndex: 2 }}>
          <MobileApplicationCard t={t} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "50px" }}>
          <Typography
            style={{
              fontFamily: "Poppins, sans-serif",
              color: COL.orange,
              fontWeight: "bold",
            }}
          >
            {t("deliveryservice")}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 50 }}>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h3"
              style={{
                color: COL.shoppingCartInNav,
                marginTop: "10px",
                fontWeight: 600,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {t("howitworksquestion")}
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} style={{ margin: "30 0 30 0" }}>
          <Container maxWidth="lg">
            <HowDoesItWork />
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginTop: "30px", backgroundColor: "white" }}
        >
          <TextPhotoBanner
            company={t("laundry")}
            title={t("laundrytohome")}
            info={t("infolaundry")}
            buttonText={t("readmore")}
            imageUrl={URL.stomerijAanHuis}
          />
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: COL.orange }}>
          <BestelEenvoudig />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", paddingBottom: "60px" }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12}>
          <TextPhotoBanner
            textPos="right"
            company={t("laundry")}
            title={t("delivered24")}
            info={t("infolaundry2")}
            buttonText={t("readmore")}
            imageUrl={URL.somerijAanHuisBanner}
          />
        </Grid>
        <Grid item xs={12} className="gradientBG">
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
