const COL = {
  logo: "#5fcae7",
  orange: "#345EE4",
  prices: "#345EE4",
  shoppingCartInNav: "#23295c",
  orangeBold: "#345EE4",
  backgroundColor: "#f7f6fb",
  titleHeader: "#23295c",
  textColor: "#7d8183",
  locationColor: "#345EE4",
  coralGreen:
    "rgba(0, 0, 0, 0) linear-gradient(45deg, rgb(123, 240, 224) 0%, rgb(122, 238, 225) 4%, rgb(124, 241, 225) 8%, rgb(124, 239, 222) 10%, rgb(131, 238, 220) 14%, rgb(155, 248, 230) 20%, rgb(163, 251, 235) 24%, rgb(159, 247, 231) 31%, rgb(158, 246, 232) 35%, rgb(139, 241, 230) 49%, rgb(149, 247, 232) 63%, rgb(138, 246, 231) 76%, rgb(132, 243, 227) 80%, rgb(133, 241, 228) 90%, rgb(128, 241, 223) 96%, rgb(129, 246, 227) 100%) repeat scroll 0% 0%",
  corlaGreenForground: "#1b4c1a",
  bottunColor: "#345EE4",
  emptyShoppinfCart: "#F0F8FF",
  productListTItle: "#345EE4",
  buttonDisabled: "#e0e0e0",
};

export default COL;
