import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import COL from "../constants/Colors";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../constants/Config";

export default function BestelEenvoudig() {
  const history = useHistory();

  const [t] = useTranslation();
  return (
    <Container maxWidth="lg">
      <ThemeProvider theme={theme}>
        <Grid
          container
          style={{ padding: 50 }}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item sm={12} md={7} align="left">
            <h1
              style={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 900,
              }}
            >
              {t("easyorder")}
            </h1>
          </Grid>
          <Grid item sm={12} md={5} align="right">
            <Button
              variant="contained"
              size="large"
              onClick={() => history.push("/bestellen/")}
              style={{
                backgroundColor: "white",
                color: COL.orange,
                borderRadius: 25,
                width: "250px",
                height: "50px",
              }}
            >
              <Typography
                style={{ fontFamily: "Poppins, sans-serif" }}
                variant="body1"
              >
                <b>{t("placeorder")}</b>
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Container>
  );
}
