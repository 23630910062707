import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import Button from "@material-ui/core/Button";
import COL from "../constants/Colors";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function EmptyShoppingcart(props) {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <div>
      <Container maxWidth="lg">
        <Card
          id="mobileView"
          style={{
            // boxShadow: "0 18px 60px 0 rgba(15, 33, 37, 0.2)",
            padding: 50,
            borderRadius: 0,
            backgroundColor: COL.emptyShoppinfCart,
          }}
        >
          <CardContent>
            <Grid>
              <Typography
                variant="h3"
                style={{
                  textAlign: "center",
                  color: COL.titleHeader,
                  fontFamily: "Poppins, sans-serif",

                  // marginTop: "10px",
                }}
              >
                {t("emptyshoppingcart")}
              </Typography>

              <Typography
                variant="h5"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {t("easyorder")}
              </Typography>

              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  onClick={() => history.push("/bestellen/")}
                  style={{
                    background: COL.bottunColor,
                    color: "white",
                    borderRadius: 25,
                    padding: 10,
                    marginTop: "20px",
                    float: "center",
                    // fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {t("laundryservices")}
                </Button>

                <Button
                  variant="contained"
                  onClick={() =>
                    history.push("/diensten/zakelijk-stomerij-wasserette/")
                  }
                  style={{
                    background: COL.bottunColor,
                    color: "white",
                    borderRadius: 25,
                    padding: 10,
                    marginTop: "20px",
                    float: "center",
                    // fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {t("Zakelijk")}
                </Button>

                <Button
                  variant="contained"
                  onClick={() => history.push("/bestellen/")}
                  style={{
                    background: COL.bottunColor,
                    color: "white",
                    borderRadius: 25,
                    padding: 10,
                    marginTop: "20px",
                    float: "center",
                    // fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {t("LaundryServices1")}
                </Button>

                <Button
                  variant="contained"
                  onClick={() => history.push("/diensten/wasabonnementen/")}
                  style={{
                    background: COL.bottunColor,
                    color: "white",
                    borderRadius: 25,
                    padding: 10,
                    marginTop: "20px",
                    float: "center",
                    // fontSize: 16,
                    fontWeight: 600,
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {t("LaundryServices4")}
                </Button>
              </Grid>
              <Typography
                variant="h5"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  textAlign: "center",
                  marginTop: 20,
                }}
              >
                {t("delivered24")}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}
