import React from "react";
import "../../css/styles.css";
import DesktopLogo from "./DesktopLogo";
import MobileLogo from "./MobileLogo";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileSize } from "../constants/Config";

export default function Logo(props) {
  const isMobile = useMediaQuery(`(min-width:${mobileSize})`);

  if (!isMobile) return <MobileLogo {...props} />;
  else return <DesktopLogo {...props} />;
}
