import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import "../../../css/styles.css";
import { useTranslation } from "react-i18next";
import DashboardProductsList from "../dashboard/DashboardProductsList";
import ResponsiveMenu from "../../layout/ResponsiveMenu";
import TopDwonBar from "../../layout/TopDwonBar";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { SERVER } from "../../constants/Config";
import LoadingIndicator from "../../layout/LoadingIndicator";

export default function ProductDashboard(props) {
  const [drawer2, setDrawer2] = useState(false);
  const [t] = useTranslation();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const token = localStorage.getItem("admintoken");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("pricepagetitle");
    if (!token) {
      history.push("/admin");
    }
  }, [t, history, token]);

  useEffect(() => {
    setLoading(true);
    const _getcodes = () => {
      axios
        .post(SERVER + "valadmin", {
          token: token,
        })
        .then((res) => {
          if (res.data === "invaliduser") {
            throw new Error("invaliduser");
          } else if (res.data === "ok") {
            setLoading(false);
          } else {
            throw new Error("nocodes");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.message === "invaliduser") {
            alert("Ongeldig gebruiker. U moet eerst inloggen");
          } else {
            alert(t("networkError"));
          }
          history.push("/admin");
          setLoading(false);
        });
    };
    _getcodes();
  }, [history, token, t]);

  if (loading) {
    return <LoadingIndicator style={{ height: 200, paddingTop: 100 }} />;
  }

  return (
    <div>
      <TopDwonBar open={drawer2} onClose={() => setDrawer2(false)} />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item x={12}>
          <Container maxWidth="lg">
            <Grid container direction="row">
              <Grid item xs={12} md={3} style={{ marginTop: "40px" }}>
                <ResponsiveMenu openDrawer={setDrawer2} />
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                style={{ marginTop: "15px" }}
                id="mobileproductlistid"
              >
                <DashboardProductsList />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}
