import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../../css/styles.css";
import URL from "../../constants/Urls";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { SERVER } from "../../constants/Config";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import LoadingIndicator from "../../layout/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: "100%",
  },
}));

function AddCoupoun({ t }) {
  const classes = useStyles();
  const [discount, setDiscount] = useState("");
  const [date, setDate] = useState("");
  const [minOrder, setMinOrder] = useState(0);
  const [nrOfUse, setUse] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const token = localStorage.getItem("admintoken");
  const history = useHistory();

  const handleSave = () => {
    try {
      if (discount === "") {
        alert("korting code mag niet leeg zijn.");
        return;
      }
      if (date === "") {
        alert("vervaldatum mag niet leeg zijn.");
        return;
      }
      if (Number(minOrder) <= 0 || Number(minOrder) === isNaN) {
        alert("minimum order moet groter dan 0 zijn");
        return;
      }
      if (Number(nrOfUse) <= 0 || Number(nrOfUse) === isNaN) {
        alert("het aantal keer moet groter dan 0 zijn");
        return;
      }
      if (
        Number(percentage) <= 0 ||
        Number(percentage) >= 100 ||
        Number(percentage) === isNaN
      ) {
        alert("korting percentage moet tussen 1 en 99 zijn");
        return;
      }
    } catch (error) {
      alert("Ingevoerde data is ongeldig. Aub check het eens nog een keer.");
      return;
    }
    axios
      .post(SERVER + "adddiscode", {
        token: token,
        discount_code: discount,
        exp_date: date,
        min_order: minOrder,
        count_using: nrOfUse,
        discount_prec: percentage,
      })
      .then((res) => {
        if (res.data === "invaliduser") {
          throw new Error("invaliduser");
        } else if (res.data === "ok") {
          alert("Kortingscode is gemaakt!");
          history.push("/admin/codes/editCoupon");
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "invaliduser") {
          alert("Ongeldig gebruiker. U moet eerst inloggen");
        } else {
          alert(t("networkError"));
        }
        history.push("/admin");
      });
  };

  return (
    <Paper style={{ marginBottom: 20 }}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          xs={4}
          style={{ textAlign: "left", fontWeight: "bold", paddingLeft: 25 }}
        >
          Kortingscode Naam
        </Grid>
        <Grid item xs={8}>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} style={{}}>
              <TextField
                label={"kortingscode"}
                variant="outlined"
                color="secondary"
                style={{ width: "95%" }}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ textAlign: "left", fontWeight: "bold", paddingLeft: 25 }}
        >
          Vervaldatum
        </Grid>
        <Grid item xs={8} style={{ textAlign: "right", paddingRight: 25 }}>
          <form className={classes.container} noValidate>
            <TextField
              id="date"
              label="vervaldatum"
              type="date"
              variant="outlined"
              defaultValue={Date().toLocaleString()}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setDate(e.target.value)}
            />
          </form>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ textAlign: "left", fontWeight: "bold", paddingLeft: 25 }}
        >
          Minimum order
        </Grid>
        <Grid item xs={8}>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} style={{}}>
              <TextField
                label={"minimum order"}
                variant="outlined"
                color="secondary"
                style={{ width: "95%" }}
                type="number"
                onChange={(e) => {
                  setMinOrder(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ textAlign: "left", fontWeight: "bold", paddingLeft: 25 }}
        >
          Een maximum instellen voor het aantal keer dat de kortingscode mag
          worden gebruikt.
        </Grid>
        <Grid item xs={8}>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} style={{}}>
              <TextField
                label={"max gebruiken"}
                variant="outlined"
                color="secondary"
                style={{ width: "95%" }}
                onChange={(e) => {
                  setUse(e.target.value);
                }}
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ textAlign: "left", fontWeight: "bold", paddingLeft: 25 }}
        >
          Korting Precentage
        </Grid>
        <Grid item xs={8}>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} style={{}}>
              <TextField
                label={"korting precentage"}
                variant="outlined"
                color="secondary"
                style={{ width: "95%" }}
                onChange={(e) => {
                  setPercentage(e.target.value);
                }}
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "97%" }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

function Content({ t }) {
  const history = useHistory();
  return (
    <Container maxWidth="lg">
      <Card
        id="mobileView"
        style={{
          boxShadow: "0  18px 60px 0 rgba(15, 33, 37, 0.2)",
          padding: 50,
          borderRadius: 0,
        }}
      >
        <CardContent>
          <CardMedia style={{ marginBottom: 10 }}>
            <img
              src={URL.logoD}
              style={{ width: 300 }}
              alt="aaron wasserette"
            />
          </CardMedia>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push("/admin/pages/");
                }}
              >
                Go back
              </Button>
            </Grid>
            <Grid item xs={12}>
              <AddCoupoun t={t} />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "left", marginBottom: "20px" }}
            ></Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function AddCouponCode(props) {
  const [t, i18n] = useTranslation();
  const en = props.en;
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const token = localStorage.getItem("admintoken");

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = "Add coupon";
    window.scrollTo(0, 0);
  }, [t]);

  useEffect(() => {
    setLoading(true);
    const _getcodes = () => {
      axios
        .post(SERVER + "valadmin", {
          token: token,
        })
        .then((res) => {
          if (res.data === "invaliduser") {
            throw new Error("invaliduser");
          } else if (res.data === "ok") {
            setLoading(false);
          } else {
            throw new Error("nocodes");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.message === "invaliduser") {
            alert("Ongeldig gebruiker. U moet eerst inloggen");
          } else {
            alert(t("networkError"));
          }
          history.push("/admin");
          setLoading(false);
        });
    };
    _getcodes();
  }, [history, token, t]);

  if (loading) {
    return <LoadingIndicator style={{ height: 200, paddingTop: 100 }} />;
  }

  return (
    <div>
      <Grid
        container
        direction="column"
        align="center"
        wrap="nowrap"
        style={{ backgroundColor: "white" }}
      >
        <Grid item xs={12} style={{ marginTop: 50, zIndex: 2 }}>
          <Content t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
