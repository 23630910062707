import React from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";

import { useDispatch } from "react-redux";
import {
  itemAdded,
  itemDecremented,
} from "../../features/reducers/shoppingCartSlice";

export default function CartEsdit(props) {
  const dispatch = useDispatch();

  const cartAdded = () => {
    dispatch(itemAdded({ id: props.id, price: props.data.price }));
  };

  const cartRemoved = () => {
    dispatch(itemDecremented({ id: props.id }));
  };
  return (
    <Grid container spacing={1} align="center" direction="row">
      <Grid item xs={12} md={8}>
        <Grid container align="center">
          <Grid item xs={2} align="left">
            <IconButton onClick={cartRemoved} style={{ color: "red" }}>
              {props.amount > 1 ? <RemoveIcon /> : <DeleteIcon />}
            </IconButton>
          </Grid>
          <Grid item xs={8} align="center">
            <Typography
              color="textSecondary"
              style={{ paddingTop: 12, fontFamily: "Poppins, sans-serif" }}
            >
              {props.amount}x€{props.data.price}
            </Typography>
          </Grid>
          <Grid item xs={2} align="left">
            <IconButton onClick={cartAdded} style={{ color: "green" }}>
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} align="center">
        <Typography color="textPrimary" style={{ paddingTop: 9 }}>
          {<b style={{ fontSize: "1.3rem" }}>€{props.totalPrice}</b>}
        </Typography>
      </Grid>
    </Grid>
  );
}
