import { SERVER } from "../constants/Config";

const URL = {
  nlFlag: SERVER + "images/nl.png",
  enFlag: SERVER + "images/en.png",
  logo: SERVER + "images/logo.jpg",
  logo2: SERVER + "images/logo2.jpg",
  logoM: SERVER + "images/mobilelogo.jpg",
  logoD: SERVER + "images/logotext.png",
  logoDnew: SERVER + "images/desktoplogo.png",
  logoDnew1: SERVER + "images/desktoplogo2.png",
  logoMnew: SERVER + "images/mobilelogo.png",
  homeBackground: SERVER + "images/background2.jpg",
  homeBackgroundSimple: SERVER + "images/background.jpg",
  howitworksBackground: SERVER + "images/bg3.jpg",
  sTstep1: SERVER + "images/stomerijaanhuis_stap1.png",
  sTstep1_iphone: SERVER + "images/stomerijaanhuis_ios_stap1.png",
  sTstep2: SERVER + "images/step2car.png",
  sTstep3: SERVER + "images/step3back.png",
  sTstep3_new: SERVER + "images/how_stap3V.png",
  sTstep3_people: SERVER + "images/step3people.jpg",
  wasabonimag: SERVER + "images/services/wasabone2.jpg",
  contactusimg: SERVER + "images/contactus.jpg",
  stomerijStap1: SERVER + "images/stomerijaanhuis_stap1.png",
  stomerijStap2: SERVER + "images/stomerijaanhuis_stap2.png",
  stomerijStap3: SERVER + "images/stomerijaanhuis_stap3.png",
  stomerijAanHuis: SERVER + "images/stomerij_bezorgd.jpg",
  somerijAanHuisBanner: SERVER + "images/stomerijaanhuis.jpg",
  quote: SERVER + "images/quotes.png",
  dienstenTapijtreiniging: SERVER + "images/tapijt-reinigen.jpg",
  dienstenKledidngrep: SERVER + "images/aaron-kledingreparatie.jpg",
  ablack: SERVER + "images/apblack.png",
  ablue: SERVER + "images/apblue.png",
  gblack: SERVER + "images/gpblack.png",
  gblue: SERVER + "images/gpblue.png",
  wstbckimg: SERVER + "images/services/wasserette2.jpg",
  zakimg: SERVER + "images/image1.jpg",
  wasimg: SERVER + "images/services/wasabone1.jpg",
  kledimg: SERVER + "images/image4.jpg",
  tapimg: SERVER + "images/image5.jpg",
  googleplayapp:
    "https://play.google.com/store/apps/details?id=nl.aaronwasserette.aaronwasserette",
  applestoreapp:
    "https://apps.apple.com/app/aaron-wasserette-stomerij/id1539337100",
};

export default URL;
