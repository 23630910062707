import React from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import ShoppingCart from "./ShoppingCart";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import URL from "../constants/Urls";
import { useTranslation } from "react-i18next";
import COL from "../constants/Colors";
import { useHistory } from "react-router-dom";

export default function DesktopLogo(props) {
  const [t, i18n] = useTranslation();
  const styles = {
    color: COL.corlaGreenForground,
    fontFamily: "Poppins, sans-serif",
  };
  const history = useHistory();

  return (
    <Grid container direction="row" alignItems="center" justify="center">
      <Grid item xs={6} sm={4} lg={6} style={{}}>
        <a href="https://www.aaronwasserette.nl">
          <img
            src={URL.logoDnew}
            alt="Aaron Wasserette"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          />
        </a>
      </Grid>
      <Grid item xs={6} sm={8} lg={6} style={{}}>
        <Grid container direction="row" alignItems="center" justify="center">
          <Grid item xs={6} md={6} lg={5} xl={4}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
              style={{}}
            >
              <Grid item xs={4} style={{}}>
                <Link
                  component="button"
                  variant="body2"
                  style={styles}
                  onClick={() => history.push("/contact/")}
                >
                  {t("contact")}
                </Link>
              </Grid>
              <Grid item xs={4} style={{}}>
                <Link
                  component="button"
                  variant="body2"
                  style={styles}
                  onClick={() => history.push("/mijn-account/")}
                >
                  {t("myaccount")}
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={6}>
                    <IconButton onClick={() => i18n.changeLanguage("nl")}>
                      <img src={URL.nlFlag} alt="nl" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={6}>
                    <IconButton onClick={() => i18n.changeLanguage("en")}>
                      <img src={URL.enFlag} alt="en" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={5} lg={4} xl={3}>
            <ShoppingCart
              borderStyle={"solid"}
              borderColor={"cornflowerblue"}
              // textColor={"white"}
              textColor={COL.corlaGreenForground}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
