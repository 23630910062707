import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import URL from "../constants/Urls";
import COL from "../constants/Colors";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

export default function Reviews() {
  const [t] = useTranslation();
  return (
    <Container maxWidth="lg">
      <Grid container direction="column">
        <Grid item xs>
          <h3
            style={{
              fontFamily: "Poppins, sans-serif",
              color: COL.orange,
              marginTop: "40px",
            }}
          >
            {t("reviews")}
          </h3>
          <h1
            style={{
              fontFamily: "Poppins, sans-serif",
              color: COL.shoppingCardInNav,
              marginBottom: "40px",
            }}
          >
            {t("whatclientssay")}
          </h1>
        </Grid>
        <Grid item xs>
          <Grid container spacing={3} style={{ flexGrow: 1 }}>
            <Grid item xs={12} md={4}>
              <Card align="left" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    color="textSecondary"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "italic",
                    }}
                  >
                    Ciangi Miang
                  </Typography>
                  <p
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: COL.shoppingCardInNav,
                    }}
                  >
                    {t("review1")}
                  </p>
                  <img
                    style={{ paddingBottom: "10px", float: "right" }}
                    src={URL.quote}
                    alt="quote"
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card align="left" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    color="textSecondary"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "italic",
                    }}
                  >
                    leontine lieffering
                  </Typography>
                  <p
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: COL.shoppingCardInNav,
                    }}
                  >
                    {t("review2")}
                  </p>
                  <img
                    style={{ paddingBottom: "10px", float: "right" }}
                    src={URL.quote}
                    alt="quote"
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card align="left" style={{ height: "100%" }}>
                <CardContent>
                  <Typography
                    color="textSecondary"
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      fontStyle: "italic",
                    }}
                  >
                    Julia Gumbs
                  </Typography>
                  <p
                    style={{
                      fontFamily: "Poppins, sans-serif",
                      color: COL.shoppingCardInNav,
                    }}
                  >
                    {t("review3")}
                  </p>
                  <img
                    style={{ paddingBottom: "10px", float: "right" }}
                    src={URL.quote}
                    alt="quote"
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <a href="https://maps.google.com/?cid=7955751589039607051">
            <Button
              variant="contained"
              style={{
                padding: "10px 30px 10px 30px",
                marginTop: "20px",
                backgroundColor: COL.bottunColor,
                color: "white",
                borderRadius: 25,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                }}
              >
                {t("readmore")}
              </Typography>
            </Button>
          </a>
        </Grid>
      </Grid>
    </Container>
  );
}
