import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import Logo from "../layout/Logo";
import { useTranslation } from "react-i18next";
import SideBar from "../layout/SideBar";
import DekBedList from "./DekBedList";
import SiteMap from "../layout/SiteMap";
import BackgroundColor from "../layout/Backgroundcolor";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import COL from "../constants/Colors";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { theme } from "../constants/Config";
import { ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

function backgroundText(t) {
  return (
    <div>
      <h2 style={{ fontFamily: "Poppins, sans-serif" }}>
        {t("dekbedstomenTitle1")}
      </h2>
      <h4 style={{ fontFamily: "Poppins, sans-serif" }}>
        {t("dekbedstomenTitle2")}
      </h4>
    </div>
  );
}

function DekbedstomenInformation({ t }) {
  return (
    <Container
      maxWidth="lg"
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 0,
      }}
    >
      <Card
        style={{
          // boxShadow: "0 18px 60px 0 rgba(15, 33, 37, 0.2)",
          // padding: 50,
          borderRadius: 0,
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <CardContent>
          <ThemeProvider theme={theme}>
            <Typography component="div">
              <Box
                textAlign="left"
                // m={1}
                style={{
                  marginTop: "10px",
                  color: "#7d8183",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("dekbedstomenTxt1")}
              </Box>
            </Typography>
            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
              }}
            >
              {t("dekbedstomenHeader1")}
            </Typography>
            <Typography component="div">
              <Box
                textAlign="left"
                // m={1}
                style={{
                  marginTop: "10px",
                  fontFamily: "Poppins, sans-serif",
                  color: "#7d8183",
                }}
              >
                {t("dekbedstomenTxt2")}
              </Box>
            </Typography>
            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                marginTop: "10px",
              }}
            >
              {t("dekbedstomenHeader2")}
            </Typography>
            <Typography component="div">
              <Box
                textAlign="left"
                // m={1}
                style={{
                  marginTop: "10px",
                  color: "#7d8183",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("dekbedstomenTxt3")}
              </Box>
            </Typography>
            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                marginTop: "10px",
              }}
            >
              {t("dekbedstomenHeader3")}
            </Typography>
            <Typography component="div">
              <Box
                textAlign="left"
                // m={1}
                style={{
                  marginTop: "10px",
                  fontFamily: "Poppins, sans-serif",
                  color: "#7d8183",
                }}
              >
                {t("dekbedstomenTxt4")}
              </Box>
            </Typography>{" "}
          </ThemeProvider>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function DekbedStomen(props) {
  const [drawer, setDrawer] = useState(false);
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("pricepagetitle");
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"prices"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"prices"} openDrawer={setDrawer} />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <BackgroundColor text={backgroundText(t)} />
        </Grid>
        <Grid item x={12}>
          <Container maxWidth="lg">
            <Grid container direction="row">
              <Grid
                item
                xs={12}
                style={{ marginTop: "15px" }}
                id="mobileproductlistid"
              >
                <DekBedList />
                <DekbedstomenInformation t={t} />
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12} className="gradientBG" style={{ zIndex: 10 }}>
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
