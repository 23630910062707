import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import { SERVER } from "./components/constants/Config";

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: SERVER + "locals/{{lng}}/{{ns}}",
      allowMultiLoading: false,
      crossDomain: true,
    },
    lng: 'nl',
    fallbackLng: "nl",
    debug: false,
    detection: {
      order: ["queryString", "cookie"],
      cache: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  }, (error, t) => {
      if (error) console.log(error);
  });

export default i18n;
