import React from "react";
import "../../css/styles.css";
import { Tab, Tabs } from "@material-ui/core";
import COL from "../constants/Colors";
import { useTranslation } from "react-i18next";
import useNavScroll from "../../features/useNavScroll";
import { useHistory } from "react-router-dom";

const navColor = {
  color: COL.logo,
  marginBottom: "2px",
  marginTop: "2px",
  fontFamily: "Poppins, sans-serif",
};

export default function NavItems(props) {
  const [t] = useTranslation();
  const scrolled = useNavScroll();
  const history = useHistory();

  return (
    <Tabs
      orientation={props.orientation}
      variant={scrolled ? "fullWidth" : "standard"}
      position="fixed"
      centered={true}
      value={props.currentPage}
      indicatorColor="primary"
      textColor="primary"
      style={{ width: "100%" }}
    >
      <Tab
        onClick={() => history.push("/")}
        label={t("home")}
        value="home"
        style={navColor}
      />
      <Tab
        onClick={() => history.push("/over-ons/")}
        label={t("aboutus")}
        value="about"
        style={navColor}
      />
      <Tab
        onClick={() => history.push("/hoe-werkt-het/")}
        label={t("howitworks")}
        value="howitworks"
        style={navColor}
      />
      <Tab
        onClick={() => history.push("/diensten/")}
        label={t("services")}
        value="services"
        style={navColor}
      />
      <Tab
        onClick={() => history.push("/bestellen/")}
        label={t("prices")}
        value="prices"
        style={{
          fontFamily: "Poppins, sans-serif",
          backgroundColor: COL.prices,
          color: "white",
        }}
      />
    </Tabs>
  );
}
