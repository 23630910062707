import React from "react";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@iconify/react";
import shoppingBasket from "@iconify/icons-fa/shopping-basket";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectTotal } from "../../features/reducers/shoppingCartSlice";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    fontFamily: "Poppins, sans-serif",
    padding: "0 4px",
  },
}))(Badge);

export default function ShoppingCart(props) {
  const total = useSelector(selectTotal);
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <Grid
      className="pointer"
      container
      justify="space-around"
      alignItems="center"
      style={{
        backgroundColor: props.backgroundColor,
        borderStyle: props.borderStyle,
        borderColor: props.borderColor,
        height: "100%",
      }}
      onClick={() => history.push("/wasmand/")}
    >
      <Grid item>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography
              style={{
                color: props.textColor,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {t("card")}
            </Typography>
          </Grid>
          <Grid item xs={12} align="left">
            <Typography
              style={{
                color: props.textColor,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              € {total.totalPrice.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton
          aria-label="cart"
          style={{
            color: props.textColor,
          }}
        >
          <StyledBadge badgeContent={total.totalAmount} color="secondary">
            <Icon icon={shoppingBasket} />
          </StyledBadge>
        </IconButton>
      </Grid>
    </Grid>
  );
}
ShoppingCart.defaultProps = {
  backgroundColor: "none",
  borderColor: "white",
  borderStyle: "none",
  textColor: "none",
};
