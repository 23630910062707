import React from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import ShoppingCart from "./ShoppingCart";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import COL from "../constants/Colors";
import NavItems from "./NavItems";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileSize } from "../constants/Config";
import useNavScroll from "../../features/useNavScroll";
// import MobileLogo from "./MobileLogo";
// import DesktopLogo from "./DesktopLogo";

function ShoppingCartInNavBar(props) {
  if (!props.scrolled) return null;
  return (
    <Grid
      item
      style={{
        width: "20%",
        backgroundColor: "cornflowerblue ",
      }}
    >
      <ShoppingCart textColor={COL.shoppingCartInNav} />
    </Grid>
  );
}

// function LogoInNavBar(props) {
//   if (!props.scrolled) return null;
//   return (
//     <Grid item xs={12} className="gradientBG" style={{ width: "100%" }}>
//       <MobileLogo />
//     </Grid>
//   );
// }

// function DesktopLogoInNavBar(props) {
//   if (!props.scrolled) return null;
//   return (
//     <Grid item xs={12} className="gradientBG" style={{ width: "100%" }}>
//       <Grid item xs={6} sm={4} lg={6} style={{ paddingTop: "10px" }}>
//         <a href="https://www.aaronwasserette.nl">
//           <img
//             src={URL.logoDnew}
//             alt="Aaron Wasserette"
//             style={{ paddingTop: "10px", paddingBottom: "10px" }}
//           />
//         </a>
//       </Grid>
//     </Grid>
//   );
// }

function DesktopView(props) {
  return (
    <div id="navbar" className={props.scrolled ? "navbar sticky" : "navbar"}>
      <Container maxWidth="lg">
        <Grid container justify="space-between">
          {/* <Grid item xs={12} className="gradientBG">
            <DesktopLogoInNavBar {...props} />
          </Grid> */}
          <Grid item style={{ width: props.scrolled ? "80%" : "100%" }}>
            <NavItems {...props} orientation="horizontal" />
          </Grid>
          <ShoppingCartInNavBar {...props} />
        </Grid>
      </Container>
    </div>
  );
}

function MobileView(props) {
  return (
    <div
      id="navbar"
      justify="space-around"
      className={props.scrolled ? "navbar sticky" : "navbar"}
    >
      <Grid container>
        {/* <Grid item xs={12} className="gradientBG">
          <LogoInNavBar {...props} />
        </Grid> */}
        <Grid item align="center" style={{ width: "15%", height: 60 }}>
          <IconButton
            onClick={() => props.openDrawer(true)}
            aria-label="more"
            style={{
              transform: "scale(2)",
              // padding: 15,
              paddingTop: 15,
            }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item align="right" style={{ width: "85%", height: 60 }}>
          <ShoppingCart backgroundColor={COL.orange} textColor="white" />
        </Grid>
      </Grid>
    </div>
  );
}

export default function NavBar(props) {
  const scrolled = useNavScroll();
  const isMobile = useMediaQuery(`(min-width:${mobileSize})`);

  if (!isMobile) return <MobileView {...props} scrolled={scrolled} />;
  return <DesktopView {...props} scrolled={scrolled} />;
}
