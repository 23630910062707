import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../../css/styles.css";
import URL from "../../constants/Urls";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { SERVER } from "../../constants/Config";
import LoadingIndicator from "../../layout/LoadingIndicator";

function Buttons() {
  const history = useHistory();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/admin/codes/addcoupon/");
          }}
        >
          Nieuw Kortingscode aanmaken
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/admin/codes/editCoupon");
          }}
        >
          Kortings aanpassen
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/admin/dashboard/");
          }}
        >
          Prijzen Aanpassen
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            localStorage.setItem("admintoken", "");
            history.push("/admin");
          }}
        >
          Uitloggen
        </Button>
      </Grid>
    </Grid>
  );
}

function Logo({ t }) {
  return (
    <Container maxWidth="lg">
      <Card
        id="mobileView"
        style={{
          boxShadow: "0  18px 60px 0 rgba(15, 33, 37, 0.2)",
          padding: 50,
          borderRadius: 0,
        }}
      >
        <CardContent>
          <CardMedia style={{ marginBottom: 10 }}>
            <img
              src={URL.logoD}
              style={{ width: 300 }}
              alt="aaron wasserette"
            />
          </CardMedia>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
              <Buttons />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function CouponCode(props) {
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const token = localStorage.getItem("admintoken");
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = "Aaron Dashboard";
    window.scrollTo(0, 0);
  }, [t]);

  useEffect(() => {
    setLoading(true);
    const _getcodes = () => {
      axios
        .post(SERVER + "valadmin", {
          token: token,
        })
        .then((res) => {
          if (res.data === "invaliduser") {
            throw new Error("invaliduser");
          } else if (res.data === "ok") {
            setLoading(false);
          } else {
            throw new Error("nocodes");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.message === "invaliduser") {
            alert("Ongeldig gebruiker. U moet eerst inloggen");
          } else {
            alert(t("networkError"));
          }
          history.push("/admin");
          setLoading(false);
        });
    };
    _getcodes();
  }, [history, token, t]);

  if (loading) {
    return <LoadingIndicator style={{ height: 200, paddingTop: 100 }} />;
  }

  return (
    <div>
      <Grid
        container
        direction="column"
        align="center"
        wrap="nowrap"
        style={{ backgroundColor: "white" }}
      >
        <Grid item xs={12} style={{ marginTop: 50, zIndex: 2 }}>
          <Logo t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
