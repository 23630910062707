import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import BackgroundImg from "../layout/BackgroundImg";
import URL from "../constants/Urls";
import COL from "../constants/Colors";
import Logo from "../layout/Logo";
import HowDoesItWork from "../layout/HowDoesItWork";
import BestelEenvoudig from "../layout/BestelEenvoudig";
import Reviews from "../layout/Reviews";
import { useTranslation } from "react-i18next";
import SiteMap from "../layout/SiteMap";
import SideBar from "../layout/SideBar";

function backgroundText(t) {
  return (
    <div>
      <h1 style={{ fontFamily: "Poppins, sans-serif" }}>
        {t("howitworkstitle1")}
      </h1>
      <h4 style={{ fontFamily: "Poppins, sans-serif" }}>
        {t("howitworkstitle2")}
      </h4>
    </div>
  );
}

export default function Works(props) {
  const [drawer, setDrawer] = useState(false);
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = t("howitworksPageTite");
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"howitworks"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"howitworks"} openDrawer={setDrawer} />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 1, marginBottom: 50 }}>
          <BackgroundImg
            url={URL.howitworksBackground}
            text={backgroundText(t)}
          />
        </Grid>
        <Grid item xs={12} style={{ margin: "30 0 30 0" }}>
          <Container maxWidth="lg">
            <HowDoesItWork />
          </Container>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ backgroundColor: COL.orange, marginTop: 40 }}
        >
          <BestelEenvoudig />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", paddingBottom: "60px" }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12} className="gradientBG">
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
