import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import COL from "../constants/Colors";
import { useHistory } from "react-router-dom";
import Divider from "@material-ui/core/Divider";

export default function SiteMap() {
  const [t] = useTranslation();
  const styles = {
    color: COL.corlaGreenForground,
    fontFamily: "Poppins, sans-serif",
  };
  const history = useHistory();

  const goToFacebook = () => {
    setTimeout(function () {
      window.location = "https://www.facebook.com/1595352297395740/";
    }, 25);
    window.location = "fb://profile/1595352297395740";
  };

  const goToInstagram = () => {
    setTimeout(function () {
      window.location = "https://www.instagram.com/aaron_wasserette/";
    }, 25);
    window.location = "instagram://aaron_wasserette";
  };

  return (
    <Container maxWidth="lg">
      <Grid container className="sitemap" spacing={2} style={{ padding: 40 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  padding: "30px 0px 30px 0px",
                  color: COL.corlaGreenForground,
                }}
              >
                {t("general")}
              </Typography>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/")}
              >
                {t("home")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/diensten/")}
              >
                {t("services")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/hoe-werkt-het/")}
              >
                {t("howitworksquestion")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/bestellen/")}
              >
                {t("placeorder")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/over-ons/")}
              >
                {t("aboutus")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/contact/")}
              >
                {t("contact")}
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs>
              <Typography
                variant="h6"
                style={{
                  padding: "30px 0px 30px 0px",
                  color: COL.corlaGreenForground,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("services")}
              </Typography>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/diensten/stomerij/")}
              >
                {t("drycleaning")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/diensten/wasserette/")}
              >
                {t("laundryservices")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/diensten/wasabonnementen/")}
              >
                {t("laundrysubscriptions")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/diensten/kledingreparatie/")}
              >
                {t("clothrepairing")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/diensten/tapijtreiniging/")}
              >
                {t("carpetcleaning")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() =>
                  history.push("/diensten/zakelijk-stomerij-wasserette/")
                }
              >
                {t("drycleaningforbuisness")}
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs>
              <Typography
                variant="h6"
                style={{
                  padding: "30px 0px 30px 0px",
                  color: COL.corlaGreenForground,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("drycleaning")}
              </Typography>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/bestellen/")}
              >
                {t("orderonline")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() =>
                  history.push("/diensten/stomerij/dekbed-stomen/")
                }
              >
                {t("duvet")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() =>
                  history.push("/diensten/stomerij/gordijnen-stomen/")
                }
              >
                {t("curtain")}
              </Link>
            </Grid>
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/diensten/stomerij/jas-stomen/")}
              >
                {t("caat")}
              </Link>
            </Grid>
            {/* <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/bestellen/")}
              >
                {t("suit")}
              </Link>
            </Grid> */}
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() =>
                  history.push("/diensten/stomerij/overhemd-stomen/")
                }
              >
                {t("shirt")}
              </Link>
            </Grid>
            {/* <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() =>
                  history.push("/diensten/stomerij/colbert-stomen/")
                }
              >
                {t("blazer")}
              </Link>
            </Grid> */}
            <Grid item xs>
              <Link
                component="button"
                style={styles}
                onClick={() => history.push("/product/bruidsjurk/")}
              >
                {t("wedding")}
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Grid container direction="column">
            <Grid item xs>
              <Typography
                variant="h6"
                style={{
                  padding: "30px 0px 30px 0px",
                  color: COL.corlaGreenForground,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("contact")}
              </Typography>
            </Grid>
            <Grid item xs>
              <Link
                onClick={() => history.push("/Algemene-Voorwaarden/")}
                component="button"
                style={styles}
              >
                {t("terms")}
              </Link>
            </Grid>
            <Grid item xs>
              <Typography style={styles}>
                <Link component="button" style={styles} onClick={goToFacebook}>
                  Facebook
                </Link>
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography style={styles}>
                <Link component="button" style={styles} onClick={goToInstagram}>
                  Instagram
                </Link>
              </Typography>
            </Grid>
            <Grid item xs style={{ paddingTop: 15 }}>
              <Typography style={styles}>Jan Luykenlaan 50</Typography>
            </Grid>
            <Grid item xs>
              <Typography style={styles}>2533 JS Den Haag</Typography>
            </Grid>
            <Grid item xs>
              <Typography style={styles}>Nederland</Typography>
            </Grid>

            <Grid item xs>
              <div style={styles}>
                <b>T</b>{" "}
                <a style={styles} href="tel:0646136631">
                  {" "}
                  0646136631{" "}
                </a>
              </div>
            </Grid>
            <Grid item xs>
              <div style={styles}>
                <b>T</b>{" "}
                <a style={styles} href="tel:0646136631">
                  {" "}
                  0707855694{" "}
                </a>
              </div>
            </Grid>
            <Grid item xs style={{}}>
              <a style={styles} href="mailto: aaronwasserettedenhaag@gmail.com">
                E-mail
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container style={{ padding: 40 }}>
        <Grid item xs>
          <Typography style={styles}>
            Copyright © {new Date().getFullYear()} Aaron Wasserette & Stomerij
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
