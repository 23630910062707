import React from "react";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import COL from "../constants/Colors";
import axios from "axios";
import { SERVER } from "../constants/Config";

export default class ContactForm extends React.Component {
  state = {
    formData: {
      email: "",
      fullname: "",
      message: "",
    },
    submitted: false,
    sending: false,
  };

  emailRef = React.createRef();
  nameRef = React.createRef();
  messageRef = React.createRef();

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleBlur = (event) => {
    this.emailRef.current.validate(event.target.value);
    this.nameRef.current.validate(event.target.value);
    this.messageRef.current.validate(event.target.value);
  };

  handleSubmit = (event) => {
    this.setState({ submitted: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 15000);
    });
    this.sendEmail(event);
  };

  sendEmail(e) {
    this.setState({ sending: true });
    axios
      .post(SERVER + "sendEmail", {
        name: this.state.formData.fullname,
        email: this.state.formData.email,
        message: this.state.formData.message,
      })
      .then((res) => {
        if (res.data === "ok") {
          alert(this.props.t("messagesent"));
          this.setState({
            formData: {
              email: "",
              fullname: "",
              message: "",
            },
            sending: false,
          });
        } else {
          throw new Error("message not sent");
        }
      })
      .catch((err) => {
        alert(this.props.t("networkErrorTitle"));
        this.setState({ sending: false });
      });
  }

  render() {
    const { formData, submitted } = this.state;
    const { t } = this.props;
    return (
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <ValidatorForm onSubmit={this.handleSubmit} instantValidate={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    ref={this.emailRef}
                    label="Email"
                    onChange={this.handleChange}
                    name="email"
                    value={formData.email}
                    validators={["required", "isEmail"]}
                    errorMessages={[t("required"), t("emailnotcorrect")]}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    ref={this.nameRef}
                    label={t("firstName")}
                    onChange={this.handleChange}
                    name="fullname"
                    value={formData.fullname}
                    variant="outlined"
                    validators={["required", "matchRegexp:^[a-zA-Z].*[s.]*$"]}
                    errorMessages={[t("required"), t("namenotvalid")]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    multiline
                    rows={5}
                    ref={this.messageRef}
                    label={t("yourMessage")}
                    onChange={this.handleChange}
                    name="message"
                    value={formData.message}
                    variant="outlined"
                    validators={["required", "maxStringLength:2000"]}
                    errorMessages={[t("required"), t("msgistobig")]}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                style={{
                  padding: "15px 30px",
                  backgroundColor: COL.bottunColor,
                  color: "white",
                  borderRadius: 25,
                  fontFamily: "Poppins, sans-serif",
                }}
                disabled={submitted}
              >
                {(submitted && t("messagesent")) ||
                  (!submitted && t("ContactSendButton"))}
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
    );
  }
}
