import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../../css/styles.css";
import URL from "../../constants/Urls";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { SERVER } from "../../constants/Config";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import LoadingIndicator from "../../layout/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: "100%",
  },
}));

function getDateString(dateString, t) {
  if (!dateString) return "";
  const date = new Date(dateString);
  if (isNaN(date)) return t(dateString);
  return (
    t("day" + date.getDay()) +
    " " +
    date.getDate() +
    " " +
    t("mon" + date.getMonth()) +
    " " +
    date.getFullYear()
  );
}

function Coupouns({ token, t, codes, setCodes }) {
  const classes = useStyles();
  let content = [];
  const [editable, setEditable] = useState(false);
  const history = useHistory();
  let inputs = {};

  const modifyItem = (data, id, index) => {
    try {
      if (data.discount_code === "") {
        alert("korting code mag niet leeg zijn.");
        return;
      }
      if (data.exp_date === "") {
        alert("vervaldatum mag niet leeg zijn.");
        return;
      }
      if (Number(data.min_order) <= 0 || Number(data.min_order) === isNaN) {
        alert("minimum order moet groter dan 0 zijn");
        return;
      }
      if (Number(data.count_using) <= 0 || Number(data.count_using) === isNaN) {
        alert("het aantal keer moet groter dan 0 zijn");
        return;
      }
      if (
        Number(data.percentage) <= 0 ||
        Number(data.percentage) >= 100 ||
        Number(data.percentage) === isNaN
      ) {
        alert("korting percentage moet tussen 1 en 99 zijn");
        return;
      }
    } catch (error) {
      alert("Ingevoerde data is ongeldig. Aub check het eens nog een keer.");
      return;
    }
    axios
      .post(SERVER + "moddiscode", {
        token: token,
        id: id,
        discount_code: data.discount_code,
        exp_date: data.exp_date,
        min_order: data.min_order,
        count_using: data.count_using,
        discount_prec: data.discount_prec,
      })
      .then((res) => {
        if (res.data === "invaliduser") {
          throw new Error("invaliduser");
        } else if (res.data === "ok") {
          alert("Kortingscode is aangepast!");
          codes[index] = { ...data, id: id, edit: false };
          setEditable(!editable);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "invaliduser") {
          alert("Ongeldig gebruiker. U moet eerst inloggen");
        } else {
          alert(t("networkError"));
        }
        history.push("/admin");
      });
  };

  function removeItem(id, index) {
    if (
      !window.confirm(
        "Wilt u zeker kortingscode '" +
          codes[index].discount_code +
          "' verwijderen?"
      )
    ) {
      return;
    }
    axios
      .post(SERVER + "deldiscode", {
        token: token,
        id: id,
      })
      .then((res) => {
        if (res.data === "invaliduser") {
          throw new Error("invaliduser");
        } else if (res.data === "ok") {
          const newList = codes.filter((item) => item.id !== id);
          setCodes(newList);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "invaliduser") {
          alert("Ongeldig gebruiker. U moet eerst inloggen");
        } else {
          alert(t("networkError"));
        }
        history.push("/admin");
      });
  }

  if (codes.length === 0) {
    return (
      <Paper style={{ padding: 20 }}>
        Aaron you need to add few coupons first!
        <Grid>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push("/admin/codes/addcoupon/");
            }}
            style={{ top: 10 }}
          >
            Add Coupouns
          </Button>
        </Grid>
      </Paper>
    );
  }

  for (const [index, data] of codes.entries()) {
    inputs[data.id] = {
      discount_code: data.discount_code,
      exp_date: new Date(data.exp_date).toISOString().slice(0, 10),
      min_order: data.min_order,
      count_using: data.count_using,
      discount_prec: data.discount_prec,
    };
    content.push(
      <Paper key={data.id} style={{ marginBottom: 20 }}>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            xs={4}
            style={{ textAlign: "left", fontWeight: "bold", paddingLeft: 25 }}
          >
            Kortingscode Naam
          </Grid>
          <Grid item xs={8}>
            {data.edit ? (
              <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} style={{}}>
                  <TextField
                    defaultValue={data.discount_code}
                    label={"kortingscode"}
                    variant="outlined"
                    color="secondary"
                    style={{ width: "95%" }}
                    onChange={(e) => {
                      inputs[data.id].discount_code = e.target.value;
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                style={{ textAlign: "right", paddingRight: 25 }}
              >
                {data.discount_code}
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            style={{ textAlign: "left", fontWeight: "bold", paddingLeft: 25 }}
          >
            Vervaldatum
          </Grid>
          <Grid item xs={8} style={{ textAlign: "right", paddingRight: 25 }}>
            {data.edit ? (
              <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} style={{}}>
                  <form className={classes.container} noValidate>
                    <TextField
                      id="date"
                      type="date"
                      variant="outlined"
                      defaultValue={new Date(data.exp_date)}
                      className={classes.textField}
                      onChange={(e) => {
                        inputs[data.id].exp_date = e.target.value;
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                style={{ textAlign: "right", paddingRight: 13 }}
              >
                {getDateString(new Date(data.exp_date).toString(), t)}
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            style={{ textAlign: "left", fontWeight: "bold", paddingLeft: 25 }}
          >
            Minimum order
          </Grid>
          <Grid item xs={8}>
            {data.edit ? (
              <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} style={{}}>
                  <TextField
                    label={"minimum order"}
                    variant="outlined"
                    defaultValue={data.min_order}
                    onChange={(e) => {
                      inputs[data.id].min_order = e.target.value;
                    }}
                    color="secondary"
                    style={{ width: "95%" }}
                    type="number"
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                style={{ textAlign: "right", paddingRight: 25 }}
              >
                €{data.min_order}
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            style={{ textAlign: "left", fontWeight: "bold", paddingLeft: 25 }}
          >
            Een maximum instellen voor het aantal keer dat de kortingscode mag
            worden gebruikt.
          </Grid>
          <Grid item xs={8}>
            {data.edit ? (
              <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} style={{}}>
                  <TextField
                    label={"max gebruiken"}
                    variant="outlined"
                    defaultValue={data.count_using}
                    onChange={(e) => {
                      inputs[data.id].count_using = e.target.value;
                    }}
                    color="secondary"
                    style={{ width: "95%" }}
                    type="number"
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                style={{ textAlign: "right", paddingRight: 25 }}
              >
                {data.count_using} keer
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            style={{ textAlign: "left", fontWeight: "bold", paddingLeft: 25 }}
          >
            Korting Precentage
          </Grid>
          <Grid item xs={8}>
            {data.edit ? (
              <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} style={{}}>
                  <TextField
                    label={"korting precentage"}
                    variant="outlined"
                    defaultValue={data.discount_prec}
                    onChange={(e) => {
                      inputs[data.id].discount_prec = e.target.value;
                    }}
                    color="secondary"
                    style={{ width: "95%" }}
                    type="number"
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                style={{ textAlign: "right", paddingRight: 25 }}
              >
                {data.discount_prec}%
              </Grid>
            )}
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "100%", marginLeft: 10 }}
              onClick={() => {
                if (!data.edit) {
                  data.edit = true;
                  setEditable(!editable);
                } else {
                  modifyItem(inputs[data.id], data.id, index);
                }
              }}
            >
              {data.edit ? "Opslaan" : "Aanpassen"}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              style={{ width: "100%", marginLeft: -10 }}
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={data.edit ? null : <DeleteIcon />}
              onClick={() => {
                if (data.edit) {
                  data.edit = false;
                  setEditable(!editable);
                } else {
                  removeItem(data.id, index);
                }
              }}
            >
              {data.edit ? "Anuleren" : "Verwijderen"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return content;
}

function Content({ t, token, codes, setCodes }) {
  const history = useHistory();

  return (
    <Container maxWidth="lg">
      <Card
        id="mobileView"
        style={{
          boxShadow: "0  18px 60px 0 rgba(15, 33, 37, 0.2)",
          padding: 50,
          borderRadius: 0,
        }}
      >
        <CardContent>
          <CardMedia style={{ marginBottom: 10 }}>
            <img
              src={URL.logoD}
              style={{ width: 300 }}
              alt="aaron wasserette"
            />
          </CardMedia>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push("/admin/pages/");
                }}
              >
                Go back
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Coupouns token={token} t={t} codes={codes} setCodes={setCodes} />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "left", marginBottom: "20px" }}
            ></Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function EditCouponCode(props) {
  const [t, i18n] = useTranslation();
  const en = props.en;
  const token = localStorage.getItem("admintoken");
  const [codes, setCodes] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = "Edit Coupon";
    window.scrollTo(0, 0);
  }, [t]);

  useEffect(() => {
    setLoading(true);
    const _getcodes = () => {
      axios
        .post(SERVER + "getcodes", {
          token: token,
        })
        .then((res) => {
          if (res.data === "invaliduser") {
            throw new Error("invaliduser");
          } else if (res.data.status === "ok") {
            let tmpCodes = res.data.codes;
            for (var item of tmpCodes) {
              item["edit"] = false;
            }
            setCodes(tmpCodes);
            setLoading(false);
          } else {
            throw new Error("nocodes");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.message === "invaliduser") {
            alert("Ongeldig gebruiker. U moet eerst inloggen");
          } else {
            alert(t("networkError"));
          }
          history.push("/admin");
          setLoading(false);
        });
    };
    _getcodes();
  }, [history, token, t]);

  if (loading) {
    return <LoadingIndicator style={{ height: 200, paddingTop: 100 }} />;
  }

  return (
    <div>
      <Grid
        container
        direction="column"
        align="center"
        wrap="nowrap"
        style={{ backgroundColor: "white" }}
      >
        <Grid item xs={12} style={{ marginTop: 50, zIndex: 2 }}>
          <Content t={t} token={token} codes={codes} setCodes={setCodes} />
        </Grid>
      </Grid>
    </div>
  );
}
