import React from "react";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import COL from "../constants/Colors";
import axios from "axios";
import { SERVER } from "../constants/Config";
import LoadingIndicator from "../layout/LoadingIndicator";

export default class Passwordforget extends React.Component {
  state = {
    formData: {
      email: "",
    },
    submitted: false,
    loading: false,
  };

  emailRef = React.createRef();

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleBlur = (event) => {
    this.emailRef.current.validate(event.target.value);
  };

  handleSubmit = (event) => {
    this.setState({ loading: true });
    axios
      .post(SERVER + "forgotpassword", {
        email: this.state.formData.email,
      })
      .then((res) => {
        alert(this.props.t("resetpasswordsent"));
        this.setState({ loading: false, formData: { email: "" } });
      })
      .catch((err) => {
        alert("networkError");
        this.setState({ loading: false });
      });
  };

  render() {
    const { formData, submitted } = this.state;
    const { t } = this.props;
    return (
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <ValidatorForm onSubmit={this.handleSubmit} instantValidate={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    ref={this.emailRef}
                    label={this.props.t("email")}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    name="email"
                    value={formData.email}
                    validators={["required", "isEmail"]}
                    errorMessages={[t("required"), t("emailnotcorrect")]}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {this.state.loading ? (
                <LoadingIndicator />
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  style={{
                    padding: "15px 30px",
                    backgroundColor: COL.bottunColor,
                    color: "white",
                    borderRadius: 25,
                    fontFamily: "Poppins, sans-serif",
                  }}
                  disabled={submitted}
                >
                  {this.props.t("ContactSendButton")}
                </Button>
              )}
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
    );
  }
}
