import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import COL from "../constants/Colors";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";

function Text(props) {
  const history = useHistory();

  return (
    <div style={{ textAlign: "left" }}>
      <h3
        style={{
          fontFamily: "Poppins, sans-serif",
          color: props.companyTextColor,
        }}
      >
        {props.company}
      </h3>
      <h2 style={{ fontFamily: "Poppins, sans-serif" }}>{props.title}</h2>
      <Typography
        style={{ fontFamily: "Poppins, sans-serif" }}
        color="textSecondary"
      >
        {props.info}
      </Typography>
      <Button
        variant="contained"
        onClick={() => history.push("/over-ons/")}
        style={{
          padding: "10px 30px 10px 30px",
          marginTop: "20px",
          backgroundColor: props.buttonBackColor,
          color: props.buttonTextColor,
          borderRadius: 25,
        }}
      >
        <Typography
          style={{ fontFamily: "Poppins, sans-serif", fontWeight: "bold" }}
        >
          {props.buttonText}
        </Typography>
      </Button>
    </div>
  );
}

function Image(props) {
  return (
    <div className={"imgBanner"}>
      <img
        src={props.imageUrl}
        alt="Aaron Wasserette"
        style={{
          marginTop: "10px",
          borderRadius: "4px",
          maxWidth: "100%",
          boxShadow: "0 18px 60px 0 rgba(15, 33, 37, 0.2)",
        }}
      />
    </div>
  );
}

export default function TextPhotoBanner(props) {
  return (
    <Container maxWidth="lg">
      <Grid container style={{ margin: "60px 0px 60px 0px" }}>
        <Grid item sm={12} md={6}>
          {props.textPos === "left" ? (
            <Text {...props} />
          ) : (
            <Image {...props} />
          )}
        </Grid>
        <Grid item sm={12} md={6}>
          {props.textPos === "left" ? (
            <Image {...props} />
          ) : (
            <Text {...props} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

TextPhotoBanner.defaultProps = {
  companyTextColor: COL.orangeBold,
  buttonBackColor: COL.orangeBold,
  buttonTextColor: "white",
  buttonOnClick: () => {},
  buttonText: "",
  textPos: "left",
};
