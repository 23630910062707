import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useProducts from "../../features/useProducts";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import IndeterminateCheckBox from "@material-ui/icons/IndeterminateCheckBox";
import AddBox from "@material-ui/icons/AddBox";
import Button from "@material-ui/core/Button";
import COL from "../constants/Colors";
import {
  itemAdded,
  itemDecremented,
  selectAllInCart,
} from "../../features/reducers/shoppingCartSlice";
import LoadingIndicator from "../layout/LoadingIndicator";

const useStyles = makeStyles(() => ({
  shopItems: {
    backgroundColor: "#000000",
    flexGrow: 1,
  },
  paper: {
    padding: 25,
    borderRadius: 0,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    width: "auto",
    height: "auto",
    // maxHeight: 70,
    maxHeight: 90,
    // maxWidth: 50,
  },

  catImage: {
    maxWidth: "100%",
    marginTop: 25,
    width: "100%",
    maxHeight: 200,
    height: 200,
    opacity: 0.8,
    objectFit: "cover",
  },
  titleSection: { fontFamily: "Poppins, sans-serif", position: "relative" },
  centered: {
    position: "absolute",
    top: "50%",
    left: "50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    color: "white",
  },
  gridSection: {
    border: "solid 2px #f7f6fb",
  },
  productTitle: {
    paddingTop: 35,
    paddingLeft: 15,
    color: COL.productListTItle,
    fontFamily: "Poppins, sans-serif",
    textAlign: "left",
  },
  productDescription: {
    textAlign: "left",
    paddingLeft: 15,
    fontFamily: "Poppins, sans-serif",
  },
  dummy: {
    visibility: "hidden",
  },
  quantityHolder: {
    padding: "10% 0 10% 0",
  },
  buttonPlus: {
    marginLeft: 10,
    color: COL.bottunColor,
    border: "solid 2px",
  },
  buttonMinus: {
    marginRight: 10,
    color: COL.bottunColor,
    border: "solid 2px",
  },
}));

export default function ProductsList() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const data = useProducts();
  const cart = useSelector(selectAllInCart);
  const [labels, setLabels] = useState({});
  const [t] = useTranslation();

  useEffect(() => {
    for (const [key] of Object.entries(data.products)) {
      if (cart[key]) setLabels((l) => ({ ...l, [key]: cart[key].amount }));
      else setLabels((l) => ({ ...l, [key]: 0 }));
    }
  }, [cart, data.products]);

  const handleAdd = (id) => {
    dispatch(itemAdded({ id, price: Number(data.products[id].price) }));
  };

  const handleRemove = (id) => {
    dispatch(itemDecremented({ id }));
  };

  let content;
  if (data.status === "loading") {
    content = <LoadingIndicator style={{ height: 200, paddingTop: 100 }} />;
  } else if (data.status === "succeeded") {
    content = [];
    for (const cat of data.categories) {
      if (cat.active.toString().toLowerCase() === "no") continue;
      content.push(
        <div className={classes.titleSection} id={cat.name} key={t(cat.name)}>
          <img className={classes.catImage} alt="shopItem" src={cat.icon} />
          <h1 className={classes.centered} key={cat.name}>
            {t(cat.name)}
          </h1>
        </div>
      );
      for (const pid of cat.products) {
        if (data.products[pid].active.toString().toLowerCase() === "no")
          continue;
        content.push(
          <article className="product-expcerpt" key={pid}>
            <div className={classes.shopItems}>
              <Paper className={classes.paper} style={{ boxShadow: "none" }}>
                <Grid container spacing={3} className={classes.gridSection}>
                  <Grid item xs={12} md={3} id="mobileProImg">
                    <ButtonBase className={classes.image} disabled>
                      <img
                        className={classes.img}
                        src={data.products[pid].icon}
                        alt="description"
                      />
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} md={9} container>
                    <Grid item xs={12} md={8} id="mobileViewProductName">
                      <Typography
                        variant="body2"
                        className={classes.productTitle}
                        component="div"
                        id="mobileproductTitle"
                      >
                        <Box
                          textAlign="left"
                          // m={1}
                          fontWeight="fontWeightBold"
                          fontSize="h6.fontSize"
                          id="mobileproductName"
                        >
                          {t(data.products[pid].name)}
                          {" - €"}
                          {data.products[pid].price}
                        </Box>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="div"
                        className={classes.productDescription}
                      >
                        <Box
                          textAlign="left"
                          id="mobileproductName"
                          // m={1}
                          fontWeight="fontWeightBold"
                        >
                          {t(data.products[pid].description)}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      align="center"
                      style={{ alignSelf: "center" }}
                      elevation={0}
                    >
                      <div
                        className={classes.quantityHolder}
                        id="mobilequantityHolder"
                        style={{ backgroundColor: "aliceblue" }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "white",
                            boxShadow: "none",
                          }}
                          size="large"
                          startIcon={
                            <IndeterminateCheckBox
                              style={{ marginRight: "-8px", fontSize: "25px" }}
                            />
                          }
                          onClick={() => handleRemove(pid)}
                          className={classes.buttonMinus}
                        ></Button>
                        <label
                          id={pid}
                          style={{
                            fontSize: "30px",
                            marginTop: "-10px",
                            // padding: "10px",
                          }}
                        >
                          {labels[pid] ? labels[pid] : 0}
                        </label>

                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "white",
                            boxShadow: "none",
                          }}
                          size="large"
                          startIcon={
                            <AddBox
                              style={{ marginRight: "-8px", fontSize: "25px" }}
                            />
                          }
                          onClick={() => handleAdd(pid)}
                          className={classes.buttonPlus}
                        ></Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </article>
        );
      }
    }
  } else if (data.status === "failed") {
    //TODO: DESIGN an NETWORK ERROR page
    content = <div>{data.error}</div>;
  }
  return <section>{content}</section>;
}
