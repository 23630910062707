import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import COL from "../constants/Colors";
import Logo from "../layout/Logo";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { theme } from "../constants/Config";
import BestelEenvoudig from "../layout/BestelEenvoudig";
import Reviews from "../layout/Reviews";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core/styles";
import SiteMap from "../layout/SiteMap";
import SideBar from "../layout/SideBar";
import { useSelector } from "react-redux";
import { selectUserInfo, userRemoved } from "../../features/reducers/userSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

function Account({ t }) {
  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(userRemoved());
    history.push("/mijn-account/");
  };

  useEffect(() => {
    if (Number(userInfo.id) <= 0) {
      history.push("/mijn-account/");
    }
  }, [userInfo.id, history]);

  return (
    <Container maxWidth="lg">
      <Card
        id="mobileView"
        style={{
          boxShadow: "0  18px 60px 0 rgba(15, 33, 37, 0.2)",
          padding: 50,
          borderRadius: 0,
        }}
      >
        <CardContent>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                fontFamily: "Poppins, sans-serif",
                marginTop: "10px",
                // fontSize: 45,
                fontWeight: 600,
                marginBottom: 20,
              }}
            >
              {t("welcome")} {userInfo.firstname} {userInfo.lastname}
            </Typography>
          </ThemeProvider>
          <Button
            style={{
              padding: "15px 30px",
              backgroundColor: COL.bottunColor,
              fontFamily: "Poppins, sans-serif",
              color: "white",
              borderRadius: 25,
            }}
            onClick={handleLogout}
            variant="contained"
          >
            logout
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function AccountSetting(props) {
  const [drawer, setDrawer] = useState(false);
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("myaccountmetatite");
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"prices"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        {/* Sticky NavBat */}
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"prices"} openDrawer={setDrawer} />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 50, zIndex: 2 }}>
          <Account t={t} />
        </Grid>
        {/* Banner */}
        <Grid
          item
          xs={12}
          style={{ marginTop: "75px", backgroundColor: COL.orange }}
        >
          <BestelEenvoudig />
        </Grid>
        {/* Reviews */}
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", paddingBottom: "60px" }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12} className="gradientBG">
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
