import React from "react";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import COL from "../constants/Colors";
import axios from "axios";
import { SERVER } from "../constants/Config";
import LoadingIndicator from "../layout/LoadingIndicator";

export default class ResetForm extends React.Component {
  state = {
    user: {
      password: "",
      repeatPassword: "",
    },
    loading: false,
  };

  componentDidMount() {
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.user.password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  handleChange = (event) => {
    const { user } = this.state;
    user[event.target.name] = event.target.value;
    this.setState({ user });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    axios
      .post(SERVER + "resetpassword", {
        token: this.props.id,
        password: this.state.user.password,
      })
      .then((res) => {
        this.setState({ loading: false });
        if (res.data === "ok") {
          alert(this.props.t("passwordupdated"));
          this.props.history.push("/mijn-account/");
        } else {
          alert(this.props.t("linknotvalid"));
        }
      })
      .catch((err) => {
        alert(this.props.t("networkError"));
        this.setState({ loading: false });
      });
  };

  render() {
    const { user, submitted } = this.state;
    const { t } = this.props;
    return (
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <ValidatorForm onSubmit={this.handleSubmit} instantValidate={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    label={t("password")}
                    onChange={this.handleChange}
                    name="password"
                    type="password"
                    validators={["required", "minStringLength: 5"]}
                    errorMessages={[
                      t("required"),
                      this.props.t("passwordnotcorrect"),
                    ]}
                    value={user.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    variant="outlined"
                    label={t("passwordconf")}
                    onChange={this.handleChange}
                    name="repeatPassword"
                    type="password"
                    validators={["isPasswordMatch", "required"]}
                    errorMessages={[t("passwordconfnotcorrect"), t("required")]}
                    value={user.repeatPassword}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {this.state.loading ? (
                <LoadingIndicator />
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  style={{
                    padding: "15px 30px",
                    backgroundColor: COL.bottunColor,
                    color: "white",
                    borderRadius: 25,
                  }}
                  disabled={submitted}
                >
                  {(submitted && "Password is Set!") ||
                    (!submitted && "Get Password ")}
                </Button>
              )}
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
    );
  }
}
