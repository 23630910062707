import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { Img } from "react-image";

export const SERVER = "https://server.aaronwasserette.nl/";

export const theme = createMuiTheme();

theme.typography.h3 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

export const mobileSize = "992px";

const noimage = "";
export const Image = ({ src, maxWidth }) => (
  <Img
    style={{
      maxWidth: maxWidth,
      height: "auto!important",
      width: "auto",
      maxHeight: 90,
    }}
    src={[src, noimage]}
  />
);
