import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import BackgroundImg from "../layout/BackgroundImg";
import URL from "../constants/Urls";
import COL from "../constants/Colors";
import Logo from "../layout/Logo";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { theme } from "../constants/Config";
import BestelEenvoudig from "../layout/BestelEenvoudig";
import Reviews from "../layout/Reviews";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core/styles";
import SiteMap from "../layout/SiteMap";
import SideBar from "../layout/SideBar";
import Link from "@material-ui/core/Link";

function LocationRijswijkView({ t }) {
  return (
    <Container maxWidth="lg">
      <Card
        id="mobileView"
        style={{
          boxShadow: "0 18px 60px 0 rgba(15, 33, 37, 0.2)",
          padding: 50,
          borderRadius: 0,
        }}
      >
        <CardContent>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
              }}
            >
              {t("rijswijkloctitle")}
            </Typography>

            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {t("rijwijkloctxt")}
            </Typography>

            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {t("closedmondayrijswijk")}
            </Typography>

            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {t("weekopntimerijswijk")}
            </Typography>

            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {t("saturdayrijswijk")}
            </Typography>

            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {t("sundayrijskwijk")}
            </Typography>
            {/* 
            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {t("specialopening")}
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {t("newyear")}
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {t("newyeardate")}
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {t("christday")}
            </Typography> */}

            <Typography
              variant="button"
              display="block"
              style={{
                fontFamily: "Poppins, sans-serif",
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                href="https://www.google.nl/maps/dir//Hendrik+Ravesteijnplein+92,+2282+GX+Rijswijk/@52.0488175,4.3306601,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47c5b6f3250be12f:0xdaa5c51cfca3e777!2m2!1d4.3328488!2d52.0488175"
                style={{ color: COL.locationColor }}
              >
                {t("lookroute")}
              </Link>
            </Typography>
          </ThemeProvider>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function LocationRijswijk(props) {
  const [drawer, setDrawer] = useState(false);
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = t("rijswijkloctitle");
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"about"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        {/* Sticky NavBat */}
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"about"} openDrawer={setDrawer} />
        </Grid>
        {/* picture */}
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <BackgroundImg url={URL.homeBackgroundSimple} />
        </Grid>
        {/* AboutUS */}
        <Grid item xs={12} style={{ marginTop: "-160px", zIndex: 2 }}>
          <LocationRijswijkView t={t} />
        </Grid>
        {/* Banner */}
        <Grid
          item
          xs={12}
          style={{ marginTop: "75px", backgroundColor: COL.orange }}
        >
          <BestelEenvoudig />
        </Grid>
        {/* Reviews */}
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", paddingBottom: "60px" }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12} className="gradientBG">
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
