import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import Logo from "../layout/Logo";
import { useTranslation } from "react-i18next";
import SideBar from "../layout/SideBar";
import JurkenList from "./JurkenList";
import SiteMap from "../layout/SiteMap";
import BackgroundColor from "../layout/Backgroundcolor";
import Container from "@material-ui/core/Container";

function backgroundText(t) {
  return (
    <div>
      <h2 style={{ fontFamily: "Poppins, sans-serif" }}>{t("wedding")}</h2>
      <h4 style={{ fontFamily: "Poppins, sans-serif" }}>
        {t("gordijnenPageTitle2")}
      </h4>
    </div>
  );
}

export default function Jurken(props) {
  const [drawer, setDrawer] = useState(false);
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t("wedding");
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"prices"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"prices"} openDrawer={setDrawer} />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <BackgroundColor text={backgroundText(t)} />
        </Grid>
        <Grid item x={12}>
          <Container maxWidth="lg">
            <Grid container direction="row">
              <Grid
                item
                xs={12}
                style={{ marginTop: "15px", marginBottom: 15 }}
                id="mobileproductlistid"
              >
                <JurkenList />
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12} className="gradientBG" style={{ zIndex: 10 }}>
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
