import React from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import COL from "../constants/Colors";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { SERVER } from "../constants/Config";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
export default class AfrekenenForm extends React.Component {
  state = {
    formData: {
      voornaam: this.props.formData.firstname,
      achternaam: this.props.formData.lastname,
      region: "",
      address: this.props.formData.address,
      housenumber: this.props.formData.house_number,
      postcode: this.props.formData.postcode,
      place: this.props.formData.city,
      telefone: this.props.formData.tel,
      email: this.props.formData.email,
      destination: "",
      extraNote: "",
      userId: this.props.formData.id,
    },
    submitted: false,
    loading: false,
    selectedPaymentMethod: "",
    payments: null,
    bankLoading: false,
    checked: false,
  };

  componentDidMount() {
    this.getPaymentMethods();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.formData) return;
    if (this.props.formData.id !== prevState.formData.userId) {
      const { formData } = this.state;
      formData["voornaam"] = this.props.formData.firstname;
      formData["achternaam"] = this.props.formData.lastname;
      formData["address"] = this.props.formData.street;
      formData["housenumber"] = this.props.formData.house_number;
      formData["postcode"] = this.props.formData.postcode;
      formData["place"] = this.props.formData.city;
      formData["telefone"] = this.props.formData.tel;
      formData["email"] = this.props.formData.email;
      formData["userId"] = this.props.formData.id;
      this.setState({ formData });
    }
  }

  firstnameRef = React.createRef();
  lastnameRef = React.createRef();
  regionRef = React.createRef();
  addressRef = React.createRef();
  housenumberRef = React.createRef();
  postcodeRef = React.createRef();
  placeRef = React.createRef();
  telefoneRef = React.createRef();
  emailRef = React.createRef();
  destinationRef = React.createRef();
  extraNoteRef = React.createRef();

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleBlur = (event) => {
    this.firstnameRef.current.validate(event.target.value);
    this.lastnameRef.current.validate(event.target.value);
    this.regionRef.current.validate(event.target.value);
    this.addressRef.current.validate(event.target.value);
    this.housenumberRef.current.validate(event.target.value);
    this.postcodeRef.current.validate(event.target.value);
    this.placeRef.current.validate(event.target.value);
    this.telefoneRef.current.validate(event.target.value);
    this.emailRef.current.validate(event.target.value);
    this.destinationRef.current.validate(event.target.value);
    this.extraNoteRef.current.validate(event.target.value);
  };

  btnCLicked = () => {
    this.checkFname();
    this.checkLname();
    this.checkTelefone();
    this.checkEmail();
    this.checkAddress();
    this.checkHousenumber();
    this.checkPostcode();
    this.checkPlace();
  };

  checkTelefone = () => {
    const { t } = this.props;
    const regex = /^[0-9]{9,}$/;
    let value = regex.test(this.state.formData.telefone);
    try {
      if (!value) {
        alert(t("phonenotcorrect"));
      }
    } catch (err) {
      alert(t("phonenotcorrect"));
      return;
    }
  };

  checkFname = () => {
    const { t } = this.props;
    const regex = /^[a-zA-Z].*[s.]*$/;
    let value = regex.test(this.state.formData.voornaam);
    try {
      if (!value) {
        alert(t("fnamenotcorrect"));
      }
    } catch (err) {
      alert(t("fnamenotcorrect"));
      return;
    }
  };

  checkLname = () => {
    const { t } = this.props;
    const regex = /^[a-zA-Z].*[s.]*$/;
    let value = regex.test(this.state.formData.achternaam);
    try {
      if (!value) {
        alert(t("lnamenotcorrect"));
      }
    } catch (err) {
      alert(t("lnamenotcorrect"));
      return;
    }
  };

  checkEmail = () => {
    const { t } = this.props;
    let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    let value = regex.test(this.state.formData.email);
    try {
      if (!value) {
        alert(t("emailnotcorrect"));
      }
    } catch (err) {
      alert(t("emailnotcorrect"));
      return;
    }
  };

  checkAddress = () => {
    const { t } = this.props;
    const regex = /^[a-zA-Z].*[s.]*$/;
    let value = regex.test(this.state.formData.address);
    try {
      if (!value) {
        alert(t("adressnotcorrectlyset"));
      }
    } catch (err) {
      alert(t("adressnotcorrectlyset"));
      return;
    }
  };

  checkHousenumber = () => {
    const { t } = this.props;
    try {
      if (this.state.formData.housenumber.length === 0) {
        alert(t("wrongNumber"));
      }
    } catch (err) {
      alert(t("wrongNumber"));
      return;
    }
  };

  checkPostcode = () => {
    const { t } = this.props;
    const regex = /^[1-9][0-9]{3} ?[a-zA-Z]{2}$/;
    let value = regex.test(this.state.formData.postcode);
    try {
      if (!value) {
        alert(t("wrongPostCode"));
      }
    } catch (err) {
      alert(t("wrongPostCode"));
      return;
    }
  };

  checkPlace = () => {
    const { t } = this.props;
    const regex = /^[a-zA-Z].*[s.]*$/;
    let value = regex.test(this.state.formData.place);
    try {
      if (!value) {
        alert(t("wrongPlace"));
      }
    } catch (err) {
      alert(t("wrongPlace"));
      return;
    }
  };

  handleSubmit = (event) => {
    let pids = {};
    for (const [id, item] of Object.entries(this.props.cart)) {
      pids[id] = { amount: item.amount };
      pids[id].deliveryDate = this.props.t("asap");
      pids[id].deliveryTime = this.props.t("asap");
      pids[id].pickupDate = this.props.t("asap");
      pids[id].pickupTime = this.props.t("asap");
    }
    let userId = "0";
    if (Number(this.state.formData.userId) > 0) {
      userId = this.state.formData.userId;
    }

    let discountCode = "";
    if (this.props.discountCode && this.props.discountCode !== "") {
      discountCode = this.props.discountCode;
    }

    try {
      if (
        !this.state.selectedPaymentMethod ||
        this.state.selectedPaymentMethod.length === 0
      ) {
        throw new Error("nopaymentselected");
      }
    } catch (err) {
      alert(this.props.t("emptypaymentmethod"));
      this.setState({ loading: false });
      return;
    }

    this.setState({ submitted: true, loading: true });
    axios
      .post(SERVER + "postcode", {
        postcode: this.state.formData.postcode.slice(0, 4),
      })
      .then((res) => {
        if (res.data && res.data.message !== "available") {
          throw new Error("nopostcode");
        }
      })
      .then(() => {
        return axios.post(SERVER + "pay", {
          pids: pids,
          userId: userId,
          comment: "nocomment",
          address:
            this.state.formData.address + " " + this.state.formData.housenumber,
          postcode: this.state.formData.postcode,
          city: this.state.formData.place,
          paymentMethod: this.state.selectedPaymentMethod,
          deliveryTypes: "N/A",
          firstName: this.state.formData.voornaam,
          lastName: this.state.formData.achternaam,
          email: this.state.formData.email,
          phone: this.state.formData.telefone,
          discountCode: discountCode,
          platform: "web",
        });
      })
      .then((res) => {
        if (res.data === "notok") {
          throw new Error("Afrekenen, server not ok");
        } else {
          window.location.assign(res.data);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.message === "nopostcode") {
          alert(this.props.t("invalidpostcodemessage"));
          return;
        }
        alert(this.props.t("networkError"));
      });
  };

  getPaymentMethods = () => {
    this.setState({ bankLoading: true });
    return axios
      .post(SERVER + "banksList")
      .then((res) => {
        if (res.data === "notok") {
          throw new Error("nobanks");
        } else {
          let options = res.data.issuers.map((bank) => {
            return (
              <option key={`payments${bank.name}`} value={bank.id}>
                {bank.name}
              </option>
            );
          });
          this.setState({ bankLoading: false, payments: options });
        }
      })
      .catch((err) => {
        alert(this.props.t("networkError"));
        this.setState({ bankLoading: false });
      });
  };

  setPayment = (event) => {
    if (event.target.value !== "") {
      this.setState({ selectedPaymentMethod: event.target.value });
    }
  };

  handleCheckBox = (event) => {
    this.setState({ checked: event.target.checked });
  };

  changeCheckBox = (event) => {
    this.setState({ checked: true });
  };

  render() {
    const { formData } = this.state;
    const { t } = this.props;

    return (
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <ValidatorForm onSubmit={this.handleSubmit} instantValidate={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/*name*/}
                <Grid
                  container
                  spacing={1}
                  style={{
                    padding: 20,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        textAlign: "left",
                        color: COL.titleHeader,
                        marginTop: "10px",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                        marginBottom: 10,
                      }}
                    >
                      {t("userInfo")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      fullWidth
                      ref={this.firstnameRef}
                      label={t("firstname") + "*"}
                      onChange={this.handleChange}
                      name="voornaam"
                      value={formData.voornaam || ""}
                      variant="outlined"
                      validators={["required", "matchRegexp:^[a-zA-Z].*[s.]*$"]}
                      errorMessages={[t("required"), t("namenotcorrect")]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      fullWidth
                      ref={this.lastnameRef}
                      label={t("lastname") + "*"}
                      onChange={this.handleChange}
                      name="achternaam"
                      value={formData.achternaam || ""}
                      variant="outlined"
                      validators={["required", "matchRegexp:^[a-zA-Z].*[s.]*$"]}
                      errorMessages={[t("required"), t("lastnamenotcorrect")]}
                    />
                  </Grid>
                  {/*Telefone */}
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      ref={this.telefoneRef}
                      label={t("telefoneNr") + "*"}
                      onChange={this.handleChange}
                      name="telefone"
                      value={formData.telefone || ""}
                      validators={["required", "matchRegexp:^[0-9]{9,}$"]}
                      errorMessages={[t("required"), t("phonenotcorrect")]}
                      variant="outlined"
                    />
                  </Grid>
                  {/*E-mailadres   */}
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      ref={this.emailRef}
                      label="Email*"
                      onChange={this.handleChange}
                      name="email"
                      value={formData.email || ""}
                      validators={["required"]}
                      errorMessages={[t("required")]}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    padding: 20,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        textAlign: "left",
                        color: COL.titleHeader,
                        fontFamily: "Poppins, sans-serif",
                        marginTop: "10px",
                        fontWeight: "bold",
                        marginBottom: 10,
                      }}
                    >
                      {t("address")}
                    </Typography>
                  </Grid>
                  {/*Land/regio  */}
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      ref={this.regionRef}
                      label="Nederland"
                      onChange={this.handleChange}
                      name="region"
                      value={formData.region || ""}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  {/*Straat en huisnummer * */}
                  <Grid item md={9} xs={6}>
                    <TextValidator
                      fullWidth
                      ref={this.addressRef}
                      label={t("streetname") + "*"}
                      onChange={this.handleChange}
                      name="address"
                      value={formData.address || ""}
                      validators={["required"]}
                      errorMessages={t("required")}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextValidator
                      fullWidth
                      ref={this.housenumberRef}
                      label={t("huisnumnername") + "*"}
                      onChange={this.handleChange}
                      name="housenumber"
                      value={formData.housenumber || ""}
                      validators={["required"]}
                      errorMessages={[t("required")]}
                      variant="outlined"
                    />
                  </Grid>
                  {/*Postcode * */}
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      ref={this.postcodeRef}
                      label="Postcode*"
                      onChange={this.handleChange}
                      name="postcode"
                      value={formData.postcode || ""}
                      validators={[
                        "required",
                        "matchRegexp:^[1-9][0-9]{3} ?[a-zA-Z]{2}$",
                      ]}
                      errorMessages={[t("required"), t("notvalidpostcode")]}
                      variant="outlined"
                    />
                  </Grid>
                  {/*Plaats  */}
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      ref={this.placeRef}
                      label={t("placeName") + "*"}
                      onChange={this.handleChange}
                      name="place"
                      value={formData.place || ""}
                      validators={["required"]}
                      errorMessages={t("required")}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {/*wachtwoord  */}
                <Grid
                  container
                  spacing={1}
                  style={{
                    padding: 20,
                  }}
                ></Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    padding: 20,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        textAlign: "left",
                        color: COL.titleHeader,
                        fontFamily: "Poppins, sans-serif",
                        marginTop: "10px",
                        fontWeight: "bold",
                        marginBottom: 10,
                      }}
                    >
                      {this.props.t("choosepaymentmethods")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} align="left">
                    <Select
                      native
                      value={this.state.selectedPaymentMethod}
                      onChange={this.setPayment}
                      style={{ fontFamily: "Poppins, sans-serif" }}
                    >
                      <option aria-label="None" value="">
                        {this.props.t("choosepaymentmethods")}
                      </option>
                      {this.state.payments}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} align="left">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.checked}
                      onChange={this.handleCheckBox}
                      name="checkedB"
                      color="primary"
                      style={{ marginRight: -10 }}
                    />
                  }
                  // style={{ fontSize: 5, fontFamily: "Poppins, sans-serif" }}
                  label={
                    <Box
                      component="div"
                      fontSize={10}
                      style={{
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {t("accept")}
                    </Box>
                  }
                />
                <a
                  href="https://www.aaronwasserette.nl/Algemene-Voorwaarden/"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    color: COL.locationColor,
                    fontSize: 10,
                    marginLeft: -12,
                  }}
                >
                  {t("terms").toLowerCase()}
                </a>
              </Grid>

              <Grid item xs={12}>
                {this.state.loading ? (
                  <Grid container direction="column">
                    <Grid item xs>
                      <CircularProgress disableShrink />
                    </Grid>
                    <Grid item xs>
                      <h4>{t("waitforpaymentweb")}</h4>
                    </Grid>
                  </Grid>
                ) : (
                  <Button
                    fullWidth
                    disabled={!this.state.checked}
                    type="submit"
                    variant="contained"
                    onClick={this.btnCLicked}
                    style={{
                      padding: 15,
                      backgroundColor: this.state.checked
                        ? COL.bottunColor
                        : COL.buttonDisabled,
                      fontFamily: "Poppins, sans-serif",
                      color: "white",
                      borderRadius: 25,
                    }}
                  >
                    {this.props.t("paynow")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
    );
  }
}
