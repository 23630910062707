import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import BackgroundImg from "../layout/BackgroundImg";
import URL from "../constants/Urls";
import COL from "../constants/Colors";
import Logo from "../layout/Logo";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { theme } from "../constants/Config";
import BestelEenvoudig from "../layout/BestelEenvoudig";
import Reviews from "../layout/Reviews";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core/styles";
import SiteMap from "../layout/SiteMap";
import SideBar from "../layout/SideBar";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

function ZakelijkInfo({ t }) {
  const history = useHistory();

  return (
    <Container maxWidth="lg">
      <Card
        style={{
          boxShadow: "0 18px 60px 0 rgba(15, 33, 37, 0.2)",
          padding: 50,
          borderRadius: 0,
        }}
      >
        <CardContent>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                fontFamily: "Poppins, sans-serif",
                marginTop: "10px",
                // fontSize: 45,
                fontWeight: 600,
              }}
            >
              {t("ZakelijkHeader1")}
            </Typography>

            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                marginTop: "10px",
                // fontSize: 16,
                fontFamily: "Poppins, sans-serif",
                fontWeight: 100,
              }}
            >
              {t("ZakelijkParagrapgh1")}
              {t(" ")}

              {t("ZakelijkWord1")}
              {t("ZakelijkWord2")}
              {t("ZakelijkWord3")}
              {t(" ")}
              {t("ZakelijkParagrapgh2")}
            </Typography>

            <Button
              variant="contained"
              onClick={() => history.push("/bestellen/")}
              style={{
                background: COL.bottunColor,
                color: "white",
                borderRadius: 25,
                padding: "15px 30px",
                marginTop: "20px",
                fontFamily: "Poppins, sans-serif",
                float: "left",
                // fontSize: 16,
                fontWeight: 100,
              }}
            >
              {t("placeorder")}
            </Button>

            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                fontFamily: "Poppins, sans-serif",
                marginTop: "100px",
                // fontSize: 45,
                fontWeight: 600,
              }}
            >
              {t("ZakelijkHeader2")}
            </Typography>

            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                fontFamily: "Poppins, sans-serif",
                marginTop: "10px",
                // fontSize: 16,
                fontWeight: 100,
              }}
            >
              {t("ZakelijkParagrapgh3")}
            </Typography>

            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                fontFamily: "Poppins, sans-serif",
                marginTop: "10px",
                // fontSize: 45,
                fontWeight: 600,
              }}
            >
              {t("ZakelijkHeader3")}
            </Typography>
            <Typography
              variant="body1"
              style={{
                textAlign: "left",
                color: COL.textColor,
                fontFamily: "Poppins, sans-serif",
                marginTop: "10px",
                // fontSize: 16,
                fontWeight: 100,
              }}
            >
              {t("ZakelijkParagrapgh4")}
            </Typography>
          </ThemeProvider>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function Zakelijk(props) {
  const [drawer, setDrawer] = useState(false);
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = t("zakelijBroweserTitle");
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"services"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        {/* Sticky NavBat */}
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"services"} openDrawer={setDrawer} />
        </Grid>
        {/* picture */}
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <BackgroundImg url={URL.zakimg} />
        </Grid>
        {/* AboutUS */}
        <Grid item xs={12} style={{ marginTop: "-160px", zIndex: 2 }}>
          <ZakelijkInfo t={t} />
        </Grid>
        {/* Banner */}
        <Grid
          item
          xs={12}
          style={{ marginTop: "75px", backgroundColor: COL.orange }}
        >
          <BestelEenvoudig />
        </Grid>
        {/* Reviews */}
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", paddingBottom: "60px" }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12} className="gradientBG">
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
