import React from "react";
import "../../css/styles.css";
import URL from "../constants/Urls";
import Grid from "@material-ui/core/Grid";

export default function MobileLogo(props) {
  return (
    <Grid container direction="row" alignItems="center" justify="center">
      <Grid item xs={12} sm={8} lg={4} style={{}}>
        <div
          id="navbar"
          justify="space-around"
          className={props.scrolled ? "navbar sticky" : "navbar"}
        >
          <a href="https://www.aaronwasserette.nl">
            <img
              src={URL.logoMnew}
              alt="Aaron Wasserette"
              style={{ display: "flex", width: "100%", height: "100%" }}
              href=""
            />
          </a>
        </div>
      </Grid>
    </Grid>
  );
}
