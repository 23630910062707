import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import Logo from "../layout/Logo";
import { useTranslation } from "react-i18next";
import SideBar from "../layout/SideBar";
import ProductsList from "./ProductsList";
import Reviews from "../layout/Reviews";
import SiteMap from "../layout/SiteMap";
import ResponsiveMenu from "../layout/ResponsiveMenu";
import TopDwonBar from "../layout/TopDwonBar";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import COL from "../constants/Colors";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { theme } from "../constants/Config";
import { ThemeProvider } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import BackgroundImg from "../layout/BackgroundImg";
import URL from "../constants/Urls";

function backgroundText(t) {
  return (
    <div>
      <h1>{t("stomerijServiceTitlePage1")}</h1>
      <h4>{t("stomerijServiceTitlePage2")}</h4>
    </div>
  );
}

function StomerijServiceInformation({ t }) {
  const history = useHistory();

  return (
    <Container
      maxWidth="lg"
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 0,
      }}
    >
      <Card
        style={{
          // boxShadow: "0 18px 60px 0 rgba(15, 33, 37, 0.2)",
          // padding: 50,
          borderRadius: 0,
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <CardContent>
          <ThemeProvider theme={theme}>
            <Typography component="div">
              <Box
                textAlign="left"
                // m={1}
                style={{
                  marginTop: "10px",
                  fontFamily: "Poppins, sans-serif",
                  color: "#7d8183",
                }}
              >
                {t("stomerijServiceText1")}
              </Box>
            </Typography>
            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
              }}
            >
              {t("stomerijServiceTile1")}
            </Typography>
            <Typography component="div">
              <Box
                textAlign="left"
                // m={1}
                style={{
                  marginTop: "10px",
                  color: "#7d8183",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("stomerijServiceText2")}
              </Box>
            </Typography>

            <Typography component="div">
              <Box
                textAlign="left"
                // m={1}
                style={{
                  marginTop: "10px",
                  fontFamily: "Poppins, sans-serif",

                  color: "#7d8183",
                }}
              >
                {t("stomerijServiceText3")}
              </Box>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Box
                style={{
                  color: COL.locationColor,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("lisitemstomerij1")}
              </Box>
            </Typography>
            <Typography
              variant="button"
              display="block"
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Box
                style={{
                  color: COL.locationColor,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("lisitemstomerij2")}
              </Box>
            </Typography>
            <Typography
              variant="button"
              display="block"
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Box
                style={{
                  color: COL.locationColor,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("lisitemstomerij3")}
              </Box>
            </Typography>
            <Typography
              variant="button"
              display="block"
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Box
                style={{
                  color: COL.locationColor,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("lisitemstomerij4")}
              </Box>
            </Typography>
            <Typography
              variant="button"
              display="block"
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Box
                style={{
                  color: COL.locationColor,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("lisitemstomerij5")}
              </Box>
            </Typography>
            <Typography
              variant="button"
              display="block"
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Box
                style={{
                  color: COL.locationColor,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("lisitemstomerij6")}
              </Box>
            </Typography>
            <Typography
              variant="button"
              display="block"
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Box
                style={{
                  color: COL.locationColor,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("lisitemstomerij7")}
              </Box>
            </Typography>
            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                marginTop: "10px",
              }}
            >
              {t("whatdostomerijDO")}
            </Typography>

            <Typography component="div">
              <Box
                textAlign="left"
                // m={1}
                style={{
                  marginTop: "10px",
                  fontFamily: "Poppins, sans-serif",
                  color: "#7d8183",
                }}
              >
                {t("stomerijServiceText4")}
              </Box>
            </Typography>

            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                marginTop: "10px",
              }}
            >
              {t("stomerijprices")}
            </Typography>

            <Typography component="div">
              <Box
                textAlign="left"
                // m={1}
                style={{
                  marginTop: "10px",
                  fontFamily: "Poppins, sans-serif",
                  color: "#7d8183",
                }}
              >
                {t("stomerijServiceText5")}
              </Box>
            </Typography>

            <Typography component="div">
              <Box
                textAlign="left"
                // m={1}
                style={{
                  marginTop: "10px",
                  color: "#7d8183",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("stomerijServiceText6")}
              </Box>
            </Typography>

            <Typography
              variant="h3"
              style={{
                textAlign: "left",
                color: COL.titleHeader,
                marginTop: "10px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
              }}
            >
              {t("intheareStmerij")}
            </Typography>

            <Typography component="div">
              <Box
                textAlign="left"
                // m={1}
                style={{
                  marginTop: "10px",
                  color: "#7d8183",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("StomerijParagrapgh6")}
              </Box>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() =>
                  history.push("/diensten/stomerij/leidschenveen/")
                }
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Leidschenveen
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/maasland/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Maasland
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() =>
                  history.push("/diensten/stomerij/midden-delfland/")
                }
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Midden-Delfland
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/monster/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Monster
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/naaldwijk/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Naaldwijk
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/nootdorp/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Nootdorp
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/pijnacker/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Pijnacker
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/poeldijk/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Poeldijk
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/rijswijk/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Rijswijk
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/scheveningen/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Scheveningen
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/voorburg/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Voorburg
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/wassenaar/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Wassenaar
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/wateringen/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Wateringen
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/westland/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Westland
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/ypenburg/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Ypenburg
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/zoetermeer/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Zoetermeer
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() =>
                  history.push("/diensten/stomerij/stomerij-leidschendam/")
                }
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Leidschendam
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/de-lier/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}De Lier
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/delft/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Delft
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/den-haag/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Den Haag
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/den-hoorn/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Den Hoorn
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/westland/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Westland
              </Link>
            </Typography>
            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/duindorp/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Duindorp
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() =>
                  history.push("/diensten/stomerij/honselersdijk/")
                }
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Honselersdijk
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/kijkduin/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Kijkduin
              </Link>
            </Typography>

            <Typography
              variant="button"
              display="block"
              style={{
                textAlign: "left",
                marginTop: "10px",
              }}
            >
              <Link
                onClick={() => history.push("/diensten/stomerij/leiden/")}
                style={{
                  fontFamily: "Poppins, sans-serif",
                  color: COL.locationColor,
                }}
                component="button"
              >
                {t("Stomerij") + " "}Leiden
              </Link>
            </Typography>
          </ThemeProvider>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function StomerijService(props) {
  const [drawer, setDrawer] = useState(false);
  const [drawer2, setDrawer2] = useState(false);
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = t("stomerijmetatitle");
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"services"}
      />
      <TopDwonBar open={drawer2} onClose={() => setDrawer2(false)} />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"services"} openDrawer={setDrawer} />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <BackgroundImg url={URL.homeBackground} text={backgroundText(t)} />
        </Grid>
        <Grid item x={12}>
          <Container maxWidth="lg">
            <Grid container direction="row">
              <Grid item xs={12} md={3} style={{ marginTop: "40px" }}>
                <ResponsiveMenu openDrawer={setDrawer2} />
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                style={{ marginTop: "15px" }}
                id="mobileproductlistid"
              >
                <ProductsList />
                <StomerijServiceInformation t={t} />
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "white",
            marginTop: "20px",
            paddingBottom: "60px",
            zIndex: 10,
          }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12} className="gradientBG" style={{ zIndex: 10 }}>
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
