import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import URL from "../constants/Urls";
import COL from "../constants/Colors";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../constants/Config";
import { useTranslation } from "react-i18next";

export default function HowDoesItWork() {
  const [t] = useTranslation();
  return (
    <Grid container spacing={3}>
      <Grid
        item
        sm={12}
        md={4}
        style={{ width: "100%", padding: 10, marginBottom: 15 }}
      >
        <Card style={{ height: "100%" }}>
          <CardContent>
            <img src={URL.sTstep1_iphone} alt="stomerijStap1" />
            <ThemeProvider theme={theme}>
              <Typography
                variant="h3"
                style={{
                  color: COL.shoppingCardInNav,
                  marginTop: "10px",
                  // fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("placeyourorder")}
              </Typography>
            </ThemeProvider>
            <Typography
              color="textSecondary"
              style={{ fontFamily: "Poppins, sans-serif", marginTop: "10px" }}
            >
              {t("ordereasy")}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        sm={12}
        md={4}
        style={{ width: "100%", height: "100%", padding: 10, marginBottom: 15 }}
      >
        <Card style={{ height: "100%" }}>
          <CardContent>
            <img src={URL.sTstep2} alt="stomerijStap2" />
            <ThemeProvider theme={theme}>
              <Typography
                variant="h3"
                style={{
                  color: COL.shoppingCardInNav,
                  marginTop: "10px",
                  // fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("laundrypickup")}
              </Typography>
            </ThemeProvider>
            <Typography
              color="textSecondary"
              style={{ fontFamily: "Poppins, sans-serif", marginTop: "10px" }}
            >
              {t("pickedup")}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        sm={12}
        md={4}
        style={{ width: "100%", padding: 10, marginBottom: 15 }}
      >
        <Card style={{ height: "100%" }}>
          <CardContent>
            <img src={URL.sTstep3_people} alt="stomerijStap3" />
            <ThemeProvider theme={theme}>
              <Typography
                variant="h3"
                style={{
                  color: COL.shoppingCardInNav,
                  marginTop: "10px",
                  // fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {t("laundrydelivery")}
              </Typography>
            </ThemeProvider>
            <Typography
              color="textSecondary"
              style={{ fontFamily: "Poppins, sans-serif", marginTop: "10px" }}
            >
              {t("delivered")}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
