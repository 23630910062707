import React from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function LoadingIndicator(props) {
  return (
    <Grid
      container
      align="center"
      style={{
        ...props.style,
        backgroundColor: props.backgroundColor,
      }}
    >
      <Grid item xs>
        <CircularProgress disableShrink color="secondary" />;
      </Grid>
    </Grid>
  );
}

LoadingIndicator.defaultProps = {
  backgroundColor: "white",
};
