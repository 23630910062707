import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import COL from "../constants/Colors";
import Logo from "../layout/Logo";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { theme } from "../constants/Config";
import BestelEenvoudig from "../layout/BestelEenvoudig";
import Reviews from "../layout/Reviews";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core/styles";
import SiteMap from "../layout/SiteMap";
import SideBar from "../layout/SideBar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AfrekenenForm from "./AfrekenenForm.js";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoginForm from "./LoginForm";
import Divider from "@material-ui/core/Divider";
import { selectTotal } from "../../features/reducers/shoppingCartSlice";
import { useSelector } from "react-redux";
import useProducts from "../../features/useProducts";
import LoadingIndicator from "../layout/LoadingIndicator";
import { selectAllInCart } from "../../features/reducers/shoppingCartSlice";
import { selectUserInfo } from "../../features/reducers/userSlice";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { SERVER } from "../constants/Config";
import { useDispatch } from "react-redux";

function calcDiscount(price, discount) {
  if (Number(discount) > 0 && Number(price) > 0) {
    return (Number(price) * (Number(discount) / 100)).toFixed(2);
  }
  return 0;
}

function ShowBoughtItems(props) {
  const t = props.t;
  return (
    <Grid item xs={12} align="left" style={{}}>
      <Grid container>
        <Grid item xs align="left">
          <Typography
            style={{ fontFamily: "Poppins, sans-serif" }}
            color="textSecondary"
          >
            {t(props.data.name)}
          </Typography>
        </Grid>
        <Grid item xs align="right">
          <Typography
            style={{ fontFamily: "Poppins, sans-serif" }}
            color="textSecondary"
          >
            {props.amount}x€{props.data.price}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function CheckoutDetails(props) {
  const t = props.t;
  const data = useProducts();
  const cart = useSelector(selectAllInCart);

  if (data.status !== "succeeded") return <LoadingIndicator />;

  let champ = "";
  let postPirce = 25;
  let price = Number(props.total.totalPrice).toFixed(2);
  let discount = calcDiscount(price, props.discount);
  price = Number(price) - Number(discount);
  let total = 0;
  let verzendcost = 25;

  if (price < 25) {
    postPirce = Number(postPirce) - Number(price);
    champ = "€" + Number(postPirce).toFixed(2);
    total = Number(verzendcost).toFixed(2);
  } else {
    total = Number(price).toFixed(2);
    champ = t("free");
  }

  return (
    <Grid
      container
      align="left"
      direction="column"
      style={{ padding: 20 }}
      id="mobileAfrekenenCheckout"
      spacing={1}
    >
      <Grid item xs>
        <Grid container direction="column" align="center" wrap="nowrap">
          {Object.entries(cart).map((arr, key) => {
            return (
              <Grid item xs key={key}>
                <ShowBoughtItems
                  {...props}
                  id={arr[0]}
                  amount={arr[1].amount}
                  totalPrice={(arr[1].price * arr[1].amount).toFixed(2)}
                  data={data.products[arr[0]]}
                  cart={cart}
                  t={t}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid container>
          <Grid item xs align="left">
            <Typography
              style={{ fontFamily: "Poppins, sans-serif" }}
              color="textSecondary"
            >
              {t("subtotal")} ({props.total.totalAmount.toFixed(2)})
            </Typography>
          </Grid>
          <Grid item xs align="right">
            <Typography color="textPrimary">
              {
                <b style={{ fontSize: "1.3rem" }}>
                  €{props.total.totalPrice.toFixed(2)}
                </b>
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid container>
          <Grid item xs align="left">
            <Typography
              style={{ fontFamily: "Poppins, sans-serif" }}
              color="textSecondary"
            >
              {t("discount")}
              {props.discount > 0 ? "(" + props.discount + "%)" : ""}
            </Typography>
          </Grid>
          <Grid item xs align="right">
            <Typography color="textPrimary">
              {
                <b style={{ fontSize: "1.3rem" }}>
                  €{Number(discount).toFixed(2)}
                </b>
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid container>
          <Grid item xs align="left">
            <Typography
              style={{ fontFamily: "Poppins, sans-serif" }}
              color="textSecondary"
            >
              {t("shipping")}
            </Typography>
          </Grid>
          <Grid item xs align="right">
            <Typography
              style={{
                fontFamily: "Poppins, sans-serif",
                color: "green",
                fontSize: "1.3rem",
              }}
            >
              {champ}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs align="center">
        <Typography
          style={{ fontFamily: "Poppins, sans-serif", color: "green" }}
        >
          {t("freeFrom")}
        </Typography>
      </Grid>
      <Grid item xs style={{ margin: "10px 0 10px 0" }}>
        <Divider />
      </Grid>
      <Grid item xs>
        <Grid container>
          <Grid item xs align="left">
            <Typography
              style={{ fontFamily: "Poppins, sans-serif" }}
              color="textSecondary"
            >
              {t("total")}
            </Typography>
          </Grid>
          <Grid item xs align="right">
            <Typography color="textPrimary">
              {
                <b style={{ fontSize: "1.3rem" }}>
                  €{Number(total).toFixed(2)}
                </b>
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function DiscountDetails(props) {
  const t = props.t;
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(false);
  const total = useSelector(selectTotal);
  const [errorText, setErrorText] = useState("");

  const handleCoupon = () => {
    setLoading(true);
    if (coupon.length === 0) {
      setErrorText(t("couponnotvalid"));
      setLoading(false);
      return;
    }
    axios
      .post(SERVER + "getDiscount", {
        code: coupon,
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status === "notok") {
          setErrorText(t("couponnotvalid"));
          return;
        }
        if (Number(total.totalPrice) < Number(res.data.min)) {
          setErrorText(t("couponcodemin") + res.data.min);
          return;
        }
        var prec = Number(res.data.prec);
        if (prec > 0) {
          props.setDiscount(prec);
          props.setDiscountCode(coupon);
          setErrorText("");
        } else {
          setErrorText(t("couponnotvalid"));
          return;
        }
      })
      .catch((err) => {
        console.log("coupon code error", err);
        alert(t("networkErrorTitle"));
        setLoading(false);
      });
  };

  return (
    <Grid container alignItems="center" justify="center">
      <Grid item xs={6}>
        <TextField
          error={errorText.length > 0}
          id="outlined-basic"
          label="Coupon Code"
          value={coupon}
          onChange={(e) => setCoupon(e.target.value)}
          variant="outlined"
          style={{ width: "100%" }}
          helperText={errorText}
        />
      </Grid>
      <Grid item xs={6}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Button
            variant="contained"
            onClick={handleCoupon}
            style={{
              backgroundColor: COL.orangeBold,
              marginLeft: "10px",
              color: "white",
              width: "100%",
              height: 60,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {t("applycoupon")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

function LoginAccordion({ t, setFormData }) {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);

  if (Number(userInfo.id) > 0) {
    return (
      <Typography
        variant="h3"
        style={{
          textAlign: "left",
          color: COL.titleHeader,
          marginTop: "10px",
          fontWeight: 600,
          marginBottom: 20,
          fontFamily: "Poppins, sans-serif",
        }}
      >
        {t("welcome")} {userInfo.firstname} {userInfo.lastname}
      </Typography>
    );
  } else {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontFamily: "Poppins, sans-serif" }}>
            {t("needtoLogin")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LoginForm dispatch={dispatch} setFormData={setFormData} t={t} />
        </AccordionDetails>
      </Accordion>
    );
  }
}

function AfForm({ t }) {
  const total = useSelector(selectTotal);
  const cart = useSelector(selectAllInCart);
  const userInfo = useSelector(selectUserInfo);
  const history = useHistory();
  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData(userInfo);
  }, [userInfo]);

  if (Object.entries(cart).length === 0) {
    history.push("/bestellen/");
  }

  return (
    <Container maxWidth="lg">
      <Card
        id="mobileView"
        style={{
          boxShadow: "0 18px 60px 0 rgba(15, 33, 37, 0.2)",
          padding: 50,
          borderRadius: 0,
        }}
      >
        <CardContent>
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <LoginAccordion setFormData={setFormData} t={t} />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 20, width: "100%" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ fontFamily: "Poppins, sans-serif" }}>
                  {t("addcoupon")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DiscountDetails
                  t={t}
                  setDiscount={setDiscount}
                  setDiscountCode={setDiscountCode}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Typography
            variant="h3"
            style={{
              textAlign: "left",
              color: COL.titleHeader,
              marginTop: "10px",
              fontWeight: 600,
              marginBottom: 20,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {t("youroder")}
          </Typography>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: "#fffae5", padding: 5, marginBottom: 20 }}
          >
            <CheckoutDetails
              discount={discount}
              userInfo={userInfo}
              t={t}
              total={total}
            />
          </Grid>
          <Typography
            variant="h3"
            style={{
              textAlign: "left",
              color: COL.titleHeader,
              fontFamily: "Poppins, sans-serif",
              marginTop: "10px",
              fontWeight: 600,
              marginBottom: 20,
            }}
          >
            {t("Factuurgegevens")}
          </Typography>
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <ThemeProvider theme={theme}>
              <AfrekenenForm
                formData={formData}
                history={history}
                cart={cart}
                discountCode={discountCode}
                t={t}
              />
            </ThemeProvider>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default function Afrekenen(props) {
  const [drawer, setDrawer] = useState(false);
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);

  useEffect(() => {
    document.title = t("checkoutmetatitle");
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"prices"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="gradientBG">
          <Logo />
        </Grid>
        {/* Sticky NavBat */}
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"prices"} openDrawer={setDrawer} />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 50, zIndex: 2 }}>
          <AfForm t={t} />
        </Grid>
        {/* Banner */}
        <Grid
          item
          xs={12}
          style={{ marginTop: "75px", backgroundColor: COL.orange }}
        >
          <BestelEenvoudig />
        </Grid>
        {/* Reviews */}
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", paddingBottom: "60px" }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12} className="gradientBG">
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
