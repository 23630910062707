import { useState, useEffect } from "react";

export default function useNavScroll(elem = "navbar") {
  const [isScrolled, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      var sticky = document.getElementById(elem).offsetTop;
      if (window.pageYOffset > sticky) setScroll(true);
      else setScroll(false);
    };
    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [elem]);

  return isScrolled;
}
