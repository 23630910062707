import React from "react";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import COL from "../constants/Colors";
import { userAdded } from "../../features/reducers/userSlice";
import LoadingIndicator from "../layout/LoadingIndicator";
import { SERVER } from "../constants/Config";
import axios from "axios";

export default class LoginForm extends React.Component {
  state = {
    formData: {
      email: "",
      password: "",
    },
    submitted: false,
    loading: false,
  };

  emailRef = React.createRef();
  passwordRef = React.createRef();

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleBlur = (event) => {
    this.emailRef.current.validate(event.target.value);
    this.passwordRef.current.validate(event.target.value);
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    axios
      .post(SERVER + "login", {
        email: this.state.formData.email,
        password: this.state.formData.password,
      })
      .then((res) => {
        if (res.data.status === "ok") {
          console.log(res.data);
          if (this.props.setFormData) {
            this.props.setFormData({
              id: res.data.details.id,
              firstname: res.data.details.firstname,
              lastname: res.data.details.lastname,
              email: res.data.details.email,
              tel: res.data.details.tel,
              token: res.data.details.token,
              street: res.data.details.street,
              house_number: res.data.details.house_number,
              postcode: res.data.details.postcode,
              city: res.data.details.city,
            });
          }
          this.props.dispatch(
            userAdded({
              id: res.data.details.id,
              firstname: res.data.details.firstname,
              lastname: res.data.details.lastname,
              email: res.data.details.email,
              tel: res.data.details.tel,
              token: res.data.details.token,
              street: res.data.details.street,
              house_number: res.data.details.house_number,
              postcode: res.data.details.postcode,
              city: res.data.details.city,
            })
          );
          this.setState({
            loading: false,
            formData: {
              email: "",
              password: "",
            },
          });
        } else {
          throw new Error("loginerror");
        }
      })
      .catch((err) => {
        alert(this.props.t("logginError"));
        this.setState({ loading: false });
      });
  };

  renderCheckbox = ({ input, label }) => (
    <Checkbox
      label={label}
      checked={input.value ? true : false}
      onCheck={input.onChange}
    />
  );

  routingFunction = (param) => {
    this.props.history.push({
      pathname: "/mijn-account/lost-password/",
      state: param,
    });
  };

  render() {
    const { formData, submitted } = this.state;
    const { t } = this.props;
    return (
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <ValidatorForm onSubmit={this.handleSubmit} instantValidate={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    ref={this.emailRef}
                    label={this.props.t("email")}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    name="email"
                    value={formData.email}
                    validators={["required"]}
                    errorMessages={t("required")}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    ref={this.passwordRef}
                    label={t("password")}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    name="password"
                    value={formData.password}
                    variant="outlined"
                    validators={["required"]}
                    errorMessages={t("required")}
                    type="password"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {this.state.loading ? (
                <LoadingIndicator />
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  style={{
                    padding: "15px 30px",
                    backgroundColor: COL.bottunColor,
                    fontFamily: "Poppins, sans-serif",
                    color: "white",
                    borderRadius: 25,
                  }}
                  disabled={submitted}
                >
                  {this.props.t("Inlogen")}
                </Button>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "left", marginBottom: "20px" }}
            ></Grid>
          </Grid>
        </ValidatorForm>
      </Container>
    );
  }
}
