import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import "../../css/styles.css";
import NavBar from "../layout/NavBar";
import URL from "../constants/Urls";
import COL from "../constants/Colors";
import Logo from "../layout/Logo";
import TextPhotoBanner from "../layout/TextPhotoBanner";
import BestelEenvoudig from "../layout/BestelEenvoudig";
import Reviews from "../layout/Reviews";
import { useTranslation } from "react-i18next";
import SiteMap from "../layout/SiteMap";
import SideBar from "../layout/SideBar";
import axios from "axios";
import { SERVER } from "../constants/Config";
import { clearCart } from "../../features/reducers/shoppingCartSlice";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import LoadingIndicator from "../layout/LoadingIndicator";

function ShowPaymentResult(props) {
  return (
    <div
      style={{
        paddingTop: 50,
        height: 300,
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      <h1>{props.status}</h1>
    </div>
  );
}

export default function ActivateAccount(props) {
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [paid, setPaid] = useState("");
  const [t, i18n] = useTranslation();
  const en = props.en;

  useEffect(() => {
    if (en) {
      i18n.changeLanguage("en");
    }
  }, [i18n, en]);
  useEffect(() => {
    document.title = t("homeTitle");
    const _checkStatus = async () => {
      try {
        const values = queryString.parse(props.location.search);
        let res = await axios.post(SERVER + "checkorderstatus", {
          id: values.id,
        });
        if (res.data === "paid") {
          setLoading(false);
          setPaid(t("orderstatuspaid"));
          dispatch(clearCart());
        } else if (res.data === "pending") {
          throw new Error("pending");
        } else if (res.data === "canceled") {
          setLoading(false);
          setPaid(t("orderstatuscanceled"));
        } else {
          throw new Error("notok");
        }
      } catch (err) {
        setLoading(false);
        setPaid(t("orderwillsendemail"));
        dispatch(clearCart());
      }
    };
    _checkStatus();
  }, [t, dispatch, props.location.search]);

  return (
    <div>
      <SideBar
        open={drawer}
        onClose={() => setDrawer(false)}
        currentPage={"home"}
      />
      <Grid container direction="column" align="center" wrap="nowrap">
        <Grid item xs={12} className="coralGreen">
          <Logo />
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <NavBar currentPage={"home"} openDrawer={setDrawer} />
        </Grid>
        <Grid item xs={12} style={{ zIndex: 1 }}>
          {loading ? (
            <LoadingIndicator style={{ height: 200, paddingTop: 100 }} />
          ) : (
            <ShowPaymentResult status={paid} />
          )}
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: COL.orange }}>
          <BestelEenvoudig />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "white", paddingBottom: "60px" }}
        >
          <Reviews />
        </Grid>
        <Grid item xs={12}>
          <TextPhotoBanner
            textPos="right"
            company={t("laundry")}
            title={t("delivered24")}
            info={t("infolaundry2")}
            buttonText={t("readmore")}
            imageUrl={URL.somerijAanHuisBanner}
          />
        </Grid>
        <Grid item xs={12} className="coralGreen">
          <SiteMap t={t} />
        </Grid>
      </Grid>
    </div>
  );
}
